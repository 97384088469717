import React from 'react';

interface ContentItem {
	text: string;
	options: string[];
	correctAnswer: string;
}

interface FillInTheBlankProps {
	content: ContentItem[];
	results: any;
	setResults: (results: any) => void;
}

const FillInTheBlank: React.FC<FillInTheBlankProps> = ({
	content,
	results,
	setResults
}) => {


	const handleAnswerChange = (index: number, value: string) => {
		setResults((prev: any) => ({
			...prev,
			[index]: value
		}));
	};

	return (
		<div className="max-w-4xl mx-auto p-4 sm:p-6 lg:p-8">
			<h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center">
				Fill in the Blanks
			</h2>


			<div className="space-y-6">
				{content.map((item, index) => (
					<div
						key={index}
						className="bg-white rounded-lg shadow-md p-4 sm:p-6"
					>
						<p className="text-lg mb-4">{item.text}</p>
						<div className="flex flex-col sm:flex-row sm:items-center gap-4">
							<select
								value={results ? results[index] : ''}
								onChange={(e) => handleAnswerChange(index, e.target.value)}
								className="border p-2 rounded-md flex-grow border-gray-300"
							>
								<option value="">Select an answer</option>
								{item.options.map((option, optionIndex) => (
									<option key={optionIndex} value={option}>
										{option}
									</option>
								))}
							</select>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default FillInTheBlank;