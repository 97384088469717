import { useNavigate } from 'react-router-dom';
import {
	MdVolumeUp,
	MdVolumeMute,
	MdMic,
	MdStop,
	MdArrowBack,
	MdHelpOutline,
	MdPause,
	MdSettings,
	MdTextFields,
	MdBook,
	MdImage
} from 'react-icons/md';

import { useTimer } from "react-timer-hook";

import styles from "./PronunciationPractice.module.css";
import { Fragment, useEffect, useRef, useState, useCallback } from "react";

import { PronunciationProgress, RecognitionCallbacks, defaultPronunciationSettings } from "../../types";

import { motion, AnimatePresence } from "framer-motion";

import { useAzure } from "../../hooks/useAzure";
import _ from "lodash";


import {
	saveBlobToStorage,
	loadBlobFromStorage,
	loadFromStorage,
	saveToStorage,
	deleteFromStorage,
	useLocalStorage,
} from "../../hooks/useLocalStorage";

import { useServer } from "../../hooks/useServer";
import { useLoading } from "../../hooks/LoadingProvider";
import PronunciationSettings from "./PronunciationSettings";

import { useToast } from "../../hooks/ToastProvider";


const containerVariants = {
	hidden: {
		opacity: 0,
		scaleX: 0,
		transformOrigin: "left"
	},
	visible: {
		opacity: 1,
		scaleX: 1,
		transition: {
			type: "spring",
			damping: 20,
			stiffness: 100
		}
	},
	exit: {
		opacity: 0,
		scaleX: 0,
		transition: {
			duration: 0.2
		}
	}
};

const PronunciationPracticePage: React.FC = () => {
	const {
		auth,
		ensureToken,
		startPronunciationAssessment,
		speakTextAsync,
		stopPronunciationAssessment,

	} = useAzure();


	const { showToast } = useToast();
	const [showSentence, setShowSentence] = useState(false);
	const [showDefinition, setShowDefinition] = useState(false);
	const [showImage, setShowImage] = useState(false);
	const [imageUrl, setImageUrl] = useState({} as any);
	const { postPronunciationAssessmentResults, generateImage, getUserProfile, getPronunciationContent } = useServer();
	const { showLoading, hideLoading, isLoading } = useLoading();
	const [showSettings, setShowSettings] = useState(false);
	const [settings, setSettings] = useLocalStorage("pronunciationSettings", defaultPronunciationSettings);
	const settingsRef = useRef(settings);

	const navigate = useNavigate();

	const stateRef = useRef<PronunciationProgress>({
		name: "",
		isAssessment: false,
		isFrequency: false,
		role: "",
		interest: "",
		metrics: [],
		remainingSeconds: 300,
		sessionId: "",
		speechElements: [],
	});

	const resultsRef = useRef<any[]>([]);
	const [reRender, setReRender] = useState(0);
	const currentRef = useRef(0);

	const [showQuitAlert, setShowQuitAlert] = useState(false);
	const [timerExpired, setTimerExpired] = useState(false);
	const timerExpiredRef = useRef(timerExpired);


	const [showTimerAlert, setShowTimerAlert] = useState(false);

	const { seconds, minutes, pause, resume, restart } = useTimer({
		expiryTimestamp: new Date(),
		autoStart: false,
		onExpire: () => {
			setTimerExpired(true);
			setShowTimerAlert(true);
		},
	});

	const [isRecognizing, setIsRecognizing] = useState(false);
	const isRecognizingRef = useRef(isRecognizing);

	const [isPlayingSlow, setIsPlayingSlow] = useState(false);
	const [isPlayingFast, setIsPlayingFast] = useState(false);
	const [displayText, setDisplayText] = useState("");

	const textareaRef = useRef<any>(null);

	const [popoverState, setPopoverState] = useState({
		showPopover: false,
		event: undefined as any,
	});

	//console.log("PronunciationPracticePage");

	useEffect(() => {
		settingsRef.current = settings;
	}, [settings]);

	useEffect(() => {
		timerExpiredRef.current = timerExpired;
	}, [timerExpired]);

	useEffect(() => {
		console.log("auth", auth);
		if (!auth) {
			console.log("No auth token. Redirecting to login.");
			ensureToken();
			showLoading("Loading...");

		} else {
			console.log("Auth token found.");
			hideLoading();
		}
	}, [auth]);

	const initializeState = async () => {
		try {
			console.log("Loading progress");
			const progress =
				await loadFromStorage<PronunciationProgress | null>(
					"pronunciationProgress",
					null
				);

			console.log("Loaded progress", progress);

			if (!progress || progress.speechElements.length == 0) {
				console.error(
					"No progress found. Redirecting to pronunciation page."
				);
				// delete
				await deleteFromStorage("pronunciationProgress");
				navigate("/pronunciation");
				return;
			}

			console.log("Resuming progress", progress);
			stateRef.current = progress;
			resultsRef.current =
				progress.results || progress.speechElements.map(() => null);

			currentRef.current = progress.current || 0;

			setReRender((prev) => prev + 1);

			//setCurrent(progress.current || 0);
			//currentRef.current = progress.current || 0;

			if (progress?.isAssessment) {
				// set expiry timestamp to 300 seconds
				const newExpiryTimestamp = new Date(
					new Date().getTime() +
					(stateRef.current.remainingSeconds || 300) * 1000
				);

				console.log("newExpiryTimestamp", newExpiryTimestamp);
				restart(newExpiryTimestamp);
			} else {
				pause();
			}
		} catch (error) {
			console.error("Error initializing:", error);
		}
	};


	useEffect(() => {
		initializeState();

		return () => {
			stopPronunciationAssessment();
		}
	}, []);

	const startRecognition = async () => {
		console.log("Starting recognition");
		setDisplayText("Listening...");
		const callbacks = {} as RecognitionCallbacks;

		callbacks.recognizing = (s: any, e: any) => {
			setDisplayText(`${e.result.text}`);
		};

		callbacks.results = async (words: any, scores: any, blob: Blob) => {
			const audiofileName = `${stateRef.current.sessionId}-${currentRef.current}`;

			await saveBlobToStorage(audiofileName, blob);
			console.log("Saving audio blob", audiofileName);

			const newResults = {
				words,
				scores,
				timestamp: new Date().toISOString(),
				audio: audiofileName,
			};

			resultsRef.current[currentRef.current] = newResults;
			handleNext();
		}
		startPronunciationAssessment(
			stateRef.current.speechElements[currentRef.current]["word"],
			callbacks
		)
	};

	useEffect(() => {
		console.log("isRecognizing", isRecognizing);
		isRecognizingRef.current = isRecognizing;

		const handleRecognitionChange = async () => {
			if (isRecognizing) {

				await startRecognition();
			} else {
				setDisplayText("Paused.");
				await stopPronunciationAssessment();
			}
		};

		handleRecognitionChange();
	}, [isRecognizing]);

	const handleNext = useCallback(async () => {
		if (
			currentRef.current < stateRef.current.speechElements.length - 1 &&
			!timerExpiredRef.current
		) {

			if (textareaRef.current) {
				const element = textareaRef.current;
				element.classList.add(styles.successAnimation);

				// Remove the animation class after 1 second
				setTimeout(() => {
					element.classList.remove(styles.successAnimation);
				}, 2000);
			}

			currentRef.current += 1;

			if (isRecognizingRef.current && settingsRef.current.autoStart) {
				await stopPronunciationAssessment();
				await startRecognition();
			} else {
				setIsRecognizing(false);
			}

			setReRender((prev) => prev + 1);
		} else {
			console.log("Finished");


			// stop the timer if it's running
			if (stateRef.current.isAssessment) {
				pause();
			}

			setIsRecognizing(false);

			showLoading("Submitting results...");

			// Get all audio blobs
			const audioPromises = resultsRef.current.map(
				(result) => result && loadBlobFromStorage(result.audio)
			);

			// Wait for all audio blobs to load
			Promise.all(audioPromises)
				.then(async (audioBlobs) => {
					// if assessment
					await postPronunciationAssessmentResults(
						resultsRef.current,
						stateRef.current.speechElements.map((el: any) => el.word),
						stateRef.current.sessionId,
						audioBlobs,
						stateRef.current.isFrequency,
						stateRef.current.isAssessment
					);

					// delete progress
					await deleteFromStorage("pronunciationProgress");

					// Delete all audio blobs
					await Promise.all(
						resultsRef.current.map(
							(result) =>
								result && deleteFromStorage(result.audio)
						)
					);

					getPronunciationContent().then(() => { // will start the setting of the content
						console.log("Pronunciation content loaded");
						// reload user
						getUserProfile().then(() => {
							console.log("User profile loaded");
						});
					});


					hideLoading();

					navigate(
						`/pronunciationResults/${stateRef.current.sessionId}`
					);
				})
				.catch((error) => {
					hideLoading();
					showToast("Error submitting results");
					console.error("Error loading audio blobs", error);
				});
		}
	}, []);

	const handleGenerateImage = async () => {
		if (stateRef.current.speechElements[currentRef.current].sentence === imageUrl?.sentence) {
			setShowImage(prev => !prev);
			return;
		}

		const signedUrl = await generateImage(
			stateRef.current.speechElements[currentRef.current].sentence
		);

		// set url as image
		setShowImage(true);
		setImageUrl({
			sentence: stateRef.current.speechElements[currentRef.current].sentence,
			imageUrl: signedUrl,
		});
	}

	const saveProgress = async () => {
		// get seconds from
		const currentTime = minutes * 60 + seconds;
		const initialTime = stateRef.current.isAssessment
			? stateRef.current.remainingSeconds ?? 300
			: 300;
		const elapsedTime = initialTime - currentTime;

		const progress: PronunciationProgress = {
			...stateRef.current,
			results: resultsRef.current,
			current: currentRef.current,
			remainingSeconds: stateRef.current.isAssessment
				? Math.max(0, initialTime - elapsedTime)
				: undefined,
		};

		await saveToStorage("pronunciationProgress", progress);
	};

	useEffect(() => {
		if (stateRef.current.speechElements.length > 0) {
			saveProgress();
		}
	}, [currentRef.current]);

	const openPopover = (e: React.MouseEvent) => {
		e.persist();
		setPopoverState({ showPopover: true, event: e });
	};

	const closePopover = () => {
		setPopoverState({ showPopover: false, event: undefined });
	};

	const getContentReason = () => {
		return (
			<span>
				This content is tailored for your interest in{" "}
				<strong>{stateRef.current.interest}</strong> and your role as{" "}
				<strong>{stateRef.current.role}</strong>. It's designed to
				improve:{" "}
				<strong>
					{stateRef.current.metrics
						? stateRef.current.metrics[currentRef.current]
						: ""}
				</strong>
				.
			</span>
		);
	};


	if (!stateRef.current.speechElements[currentRef.current]) {
		return (
			<div className="min-h-screen">
				<div className="h-full p-4">
					<div className="flex flex-col items-center justify-center h-full">
						<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
						<p className="mt-4">Loading...</p>
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className="min-h-screen flex flex-col">
			{/* Header */}
			<header className="bg-white border-b">
				<div className="px-4 py-2">
					<div className="flex items-center justify-between">
						<button
							onClick={() => setShowQuitAlert(true)}
							className="flex items-center text-gray-600 hover:text-gray-900"
						>
							<MdArrowBack className="mr-1" />
							Quit
						</button>

						{stateRef.current.isAssessment && (
							<div className={`mr-4 ${minutes === 0 && seconds <= 30 ? "text-red-600 font-bold" : ""}`}>
								Time left: {minutes}:
								{seconds < 10 ? `0${seconds}` : seconds}
							</div>
						)}

						<button
							onClick={() => setShowSettings(true)}
							className="p-2 hover:bg-gray-100 rounded-full"
						>
							<MdSettings className="text-xl" />
						</button>
					</div>

					{/* Progress Bar */}
					<div className="w-full bg-gray-200 h-1 mt-2">
						<div
							className="bg-blue-600 h-1 transition-all duration-300"
							style={{
								width: `${((currentRef.current + 1) / stateRef.current.speechElements.length) * 100}%`
							}}
						></div>
					</div>
				</div>
			</header>

			{/* Main Content */}
			<main className={`flex-1 p-4 ${isLoading ? "opacity-50 blur-sm" : ""}`}>
				<div className="flex flex-col justify-between max-w-xl mx-auto pt-20">
					{/* Help Icon */}
					{!stateRef.current.isAssessment &&
						stateRef.current.interest &&
						stateRef.current.role &&
						stateRef.current.metrics &&
						stateRef.current.metrics[currentRef.current] && (
							<div className="absolute top-2 right-2">
								<button
									onClick={openPopover}
									className="text-gray-500 hover:text-gray-700"
								>
									<MdHelpOutline className="text-2xl" />
								</button>
								{popoverState.showPopover && (
									<div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg p-4 z-50">
										<p>{getContentReason()}</p>
									</div>
								)}
							</div>
						)}
					{/* Word Section */}
					<div className="flex-grow flex flex-col justify-center text-center">
						<p className="text-lg font-semibold mb-2">Try saying:</p>
						<div className="w-4/5 relative mx-auto">
							<textarea
								value={stateRef.current.speechElements[currentRef.current].word}
								readOnly
								className="w-full p-4 bg-gray-100 rounded-lg shadow-inner resize-none min-h-[100px] text-center"
								ref={textareaRef}
							/>

							{/* Playback Buttons */}
							<div>
								<div className="absolute bottom-3 left-0 translate-y-1/2 flex gap-2">
									<button
										onClick={() => {
											if (isPlayingSlow) return;
											setIsPlayingSlow(true);
											speakTextAsync({
												text: stateRef.current.speechElements[currentRef.current].word,
												rate: -100,
												cb: () => {
													setIsPlayingSlow(false);
												},
											});
										}}
										className="p-2 rounded-full hover:bg-gray-200"
									>
										{isPlayingSlow ? (
											<MdStop className="text-xl" />
										) : (
											<MdVolumeMute className="text-xl" />
										)}
									</button>
									<button
										onClick={() => {
											if (isPlayingFast) return;
											setIsPlayingFast(true);
											speakTextAsync({
												text: stateRef.current.speechElements[currentRef.current].word,
												cb: () => {
													setIsPlayingFast(false);
												},
											});
										}}
										className="p-2 rounded-full hover:bg-gray-200"
									>
										{isPlayingFast ? (
											<MdPause className="text-xl" />
										) : (
											<MdVolumeUp className="text-xl" />
										)}
									</button>
								</div>

								<div className="absolute bottom-3 right-0 translate-y-1/2 flex gap-2">
									{stateRef.current.speechElements[currentRef.current].sentence && (
										<button
											onClick={() => {
												setShowSentence(prev => !prev);
												setShowDefinition(false);
												setShowImage(false);
											}}
											className="p-2 rounded-full hover:bg-gray-200"
										>
											<MdTextFields className="text-xl" />
										</button>
									)}

									{stateRef.current.speechElements[currentRef.current].definition && (
										<button
											onClick={() => {
												setShowDefinition(prev => !prev);
												setShowSentence(false);
												setShowImage(false);
											}}
											className="p-2 rounded-full hover:bg-gray-200"
										>
											<MdBook className="text-xl" />
										</button>
									)}

									{stateRef.current.speechElements[currentRef.current].sentence && (
										<button
											onClick={() => {
												handleGenerateImage();
												setShowSentence(false);
												setShowDefinition(false);
											}}
											className="p-2 rounded-full hover:bg-gray-200"
										>
											<MdImage className="text-xl" />
										</button>
									)}
								</div>
							</div>
						</div>

						{/* Display Areas */}
						{showSentence && (
							<AnimatePresence>
								<motion.div
									className="mb-8"
									variants={containerVariants}
									initial="hidden"
									animate="visible"
									exit="exit"
								>
									<p className="text-md font-medium mt-6">Example Sentence:</p>
									<motion.div className="p-3 rounded-lg">
										{(() => {
											const sentence = stateRef.current.speechElements[currentRef.current].sentence;
											const word = stateRef.current.speechElements[currentRef.current].word;

											// Use word boundaries \b in the regex to match exact words
											const regex = new RegExp(`\\b(${word})\\b`, 'i');
											const parts = sentence.split(regex);

											return parts.map((part: string, index: number) =>
												part.toLowerCase() === word.toLowerCase() ? (
													<span key={index} className="bg-yellow-200 font-semibold">
														{part}
													</span>
												) : (
													<span key={index}>{part}
													</span>
												));
										})()}
									</motion.div>
								</motion.div>
							</AnimatePresence>
						)}

						{/* Definition Display */}
						{showDefinition && (
							<AnimatePresence>
								<motion.div
									className="mb-8"
									variants={containerVariants}
									initial="hidden"
									animate="visible"
									exit="exit"
								>
									<p className="text-md font-medium mt-6">Definition:</p>
									<motion.div className="p-3 bg-gray-50 rounded-lg">
										{stateRef.current.speechElements[currentRef.current].definition}
									</motion.div>
								</motion.div>
							</AnimatePresence>
						)}

						{/* Image Display */}
						{showImage && (
							<div className="mb-4">
								<p className="text-md font-medium">Image:</p>
								<div className="p-3 bg-gray-50 rounded-lg">
									<img
										src={imageUrl.imageUrl}
										alt="Word visualization"
										className="w-60 h-auto rounded-lg mx-auto"
									/>
								</div>
							</div>
						)}

						{/* Recognition Section */}
						<div className="mt-6">
							<p className="text-lg font-semibold mb-2">Recognized Speech:</p>
							<textarea
								value={displayText}
								readOnly
								className="w-4/5 p-4 bg-gray-100 rounded-lg shadow-inner resize-none mb-4 mx-auto block  text-center"
								rows={3}
							/>

							<button
								onClick={handleNext}
								className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
							>
								<span className="text-sm">
									{currentRef.current === stateRef.current.speechElements.length - 1 || timerExpiredRef.current
										? "Finish"
										: stateRef?.current?.results?.[currentRef.current]
											? "Next"
											: "Skip"}
								</span>
							</button>
						</div>

						{/* Floating Action Button */}
						<div className="fixed bottom-8 left-1/2 transform -translate-x-1/2">
							<button
								onClick={() => setIsRecognizing(!isRecognizing)}
								className={`w-[70px] h-[70px] rounded-full flex items-center justify-center shadow-lg ${isRecognizing ? 'bg-red-600' : 'bg-blue-600'
									} hover:${isRecognizing ? 'bg-red-700' : 'bg-blue-700'} transition-colors`}
							>
								{isRecognizing ? (
									<MdPause className="text-white text-3xl" />
								) : (
									<MdMic className="text-white text-3xl" />
								)}
							</button>
						</div>

						{/* Settings Component */}
						{showSettings && (
							<PronunciationSettings
								showSettings={showSettings}
								setShowSettings={setShowSettings}
								settings={settings}
								setSettings={setSettings}
							/>
						)}

						{/* Alerts */}
						{showTimerAlert && (
							<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
								<div className="bg-white rounded-lg p-6 max-w-sm mx-4">
									<h3 className="text-xl font-semibold mb-2">Time's Up!</h3>
									<p className="mb-4">The timer has finished. Please complete your last response and submit.</p>
									<button
										onClick={() => setShowTimerAlert(false)}
										className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
									>
										OK
									</button>
								</div>
							</div>
						)}

						{showQuitAlert && (
							<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
								<div className="bg-white rounded-lg p-6 max-w-sm mx-4">
									<h3 className="text-xl font-semibold mb-2">Quit Assessment</h3>
									<p className="mb-4">Are you sure you want to quit? Your progress will be saved.</p>
									<div className="flex justify-end gap-2">
										<button
											onClick={() => setShowQuitAlert(false)}
											className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-100"
										>
											Cancel
										</button>
										<button
											onClick={() => {
												pause();
												setIsRecognizing(false);
												saveProgress();
												navigate("/pronunciation");
												setShowQuitAlert(false);
											}}
											className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
										>
											Quit
										</button>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</main>
		</div>
	);
};

export default PronunciationPracticePage;