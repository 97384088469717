import { Routes, Route, Navigate, useLocation, NavLink, BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { FaHammer, FaMicrophone, FaUser, FaComments, FaCog } from "react-icons/fa";

/* Providers */
import { AuthProvider, useAuth } from "./hooks/useAuth";
import { AzureProvider } from "./hooks/useAzure";
import { ServerProvider } from "./hooks/useServer";
import { LoadingProvider } from "./hooks/LoadingProvider";
import { ToastProvider } from "./hooks/ToastProvider";
import { BlendShapeProvider } from "./hooks/BlendShapeProvider";

/* Pages */
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
import Roleplay from "./pages/roleplay/Roleplay";
import PronunciationPage from "./pages/pronunciation/Page";
import PronunciationPracticePage from "./pages/pronunciation/PronunciationPractice";
import PronunciationHistoryPage from "./pages/pronunciation/PronunciationHistory";
import PronunciationResultsPage from "./pages/pronunciation/PronunciationResults";
import SideMenu from "./components/SideMenu";
import LiveTutor from "./pages/livetutor/Page";
import Tools from "./pages/Tools";
import Login from "./pages/Login";
import Gallery from "./pages/Gallery";
import Landing from "./pages/Landing";
import Chat from "./pages/roleplay/Chat";
import OnboardingPage from "./pages/Onboarding";
import Welcome from "./pages/Welcome";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/ToC";
import CreateRoleplayPage from "./pages/roleplay/CreateRoleplay";
import CustomContextMenu from "./components/CustomContextMenu";
import HotStreak from "./components/HotStreak";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
	const { currentUser, claims, loading } = useAuth();
	const location = useLocation();

	if (loading) {
		return null;
	}

	if (currentUser === null) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	if (!(claims?.admin || claims?.betaUser)) {
		return <Navigate to="/welcome" state={{ from: location }} replace />;
	}

	return <>{children}</>;
};

const Navigation: React.FC = () => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 993);
	const [showNavBar, setShowNavBar] = useState(false);

	const location = useLocation();

	// get window path

	const keepNavBar = ["tools", "pronunciation", "profile", "roleplay", "settings"];


	console.log("path", showNavBar, isMobile);
	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 993);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		setShowNavBar(keepNavBar.some((route) => location.pathname.includes(route)));
	}, [location.pathname]); // Trigger on path change


	const MobileNavigation = () => (
		<nav className="fixed bottom-0 w-full bg-white border-t-2 p-2">
			<div className="flex justify-around items-center">
				<NavLink to="/tools" className="flex flex-col items-center">
					<FaHammer className="text-xl" />
					<span className="text-sm">Tools</span>
				</NavLink>
				<NavLink to="/pronunciation" className="flex flex-col items-center">
					<FaMicrophone className="text-xl" />
					<span className="text-sm">Pronunciation</span>
				</NavLink>
				<NavLink to="/profile" className="flex flex-col items-center">
					<FaUser className="text-xl" />
					<span className="text-sm">Profile</span>
				</NavLink>
				<NavLink to="/roleplay" className="flex flex-col items-center">
					<FaComments className="text-xl" />
					<span className="text-sm">Roleplay</span>
				</NavLink>
				<NavLink to="/settings" className="flex flex-col items-center">
					<FaCog className="text-xl" />
					<span className="text-sm">Settings</span>
				</NavLink>
			</div>
		</nav>
	);

	return (

		<div className="flex min-h-screen w-full">
			{!isMobile && showNavBar && <SideMenu />}
			<div className={`flex-1 flex flex-col ${isMobile && showNavBar ? 'pb-16' : ''}`}>
				<div className="flex-1">
					<Routes>
						<Route path="/liveTutor" element={<ProtectedRoute><LiveTutor /></ProtectedRoute>} />
						<Route path="/welcome" element={<Welcome />} />
						<Route path="/tools" element={<ProtectedRoute><Tools /></ProtectedRoute>} />
						<Route path="/pronunciation" element={<ProtectedRoute><PronunciationPage /></ProtectedRoute>} />
						<Route path="/pronunciationPractice" element={<ProtectedRoute><PronunciationPracticePage /></ProtectedRoute>} />
						<Route path="/pronunciationHistory" element={<ProtectedRoute><PronunciationHistoryPage /></ProtectedRoute>} />
						<Route path="/pronunciationResults/:sessionId" element={<ProtectedRoute><PronunciationResultsPage /></ProtectedRoute>} />
						<Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
						<Route path="/roleplay" element={<ProtectedRoute><Roleplay /></ProtectedRoute>} />
						<Route path="/chat" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
						<Route path="/create-your-own-roleplay" element={<ProtectedRoute><CreateRoleplayPage /></ProtectedRoute>} />
						<Route path="/onboarding" element={<ProtectedRoute><OnboardingPage /></ProtectedRoute>} />
						<Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
						<Route path="/login" element={<Login />} />
						<Route path="/gallery" element={<ProtectedRoute><Gallery /></ProtectedRoute>} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
						<Route path="/" element={<Landing />} />
						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
					{isMobile && showNavBar && <MobileNavigation />}
				</div>
			</div>

		</div>
	);
};

const App: React.FC = () => {
	return (
		<BrowserRouter>
			<AuthProvider>
				<ServerProvider>
					<BlendShapeProvider>
						<AzureProvider>
							<ToastProvider>
								<LoadingProvider>
									<div className="w-full">
										<HotStreak />
										<Navigation />
										<CustomContextMenu />
									</div>
								</LoadingProvider>
							</ToastProvider>
						</AzureProvider>
					</BlendShapeProvider>
				</ServerProvider>
			</AuthProvider>
		</BrowserRouter>
	);
};

export default App;