import { useState } from 'react';
import { Exercise, LiveTutorSettings } from '../../types';
import { IoVolumeMute, IoVolumeHigh, IoVolumeLow } from 'react-icons/io5';
import { IoClose } from 'react-icons/io5';

interface LiveTutorSettingsProps {
	showSettings: boolean;
	setShowSettings: (showSettings: boolean) => void;
	setExercise: (exercise: Exercise | null) => void;
	fetchExercise: () => Promise<void>;
	settings: LiveTutorSettings;
	setSettings: (key: keyof LiveTutorSettings, value: any) => void;
}

const LiveTutorSettingsModal: React.FC<LiveTutorSettingsProps> = ({
	showSettings,
	setShowSettings,
	setExercise,
	fetchExercise,
	settings,
	setSettings
}) => {
	if (!showSettings) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
			<div className="bg-white rounded-lg w-full max-w-md mx-4">
				{/* Header */}
				<div className="border-b px-4 py-3 flex justify-between items-center">
					<h2 className="text-lg font-semibold">Settings</h2>
					<button
						onClick={() => setShowSettings(false)}
						className="text-gray-500 hover:text-gray-700"
					>
						<IoClose size={24} />
					</button>
				</div>

				<div className="p-4">
					<div className="space-y-6">
						{/* Volume Control */}
						<div className="space-y-2">
							<label className="block text-sm font-medium">Volume</label>
							<div className="flex items-center gap-2">
								{settings.volume === 0 ? (
									<IoVolumeMute size={20} />
								) : (
									<IoVolumeLow size={20} />
								)}
								<input
									type="range"
									min="0"
									max="100"
									value={settings.volume}
									onChange={(e) => setSettings('volume', Number(e.target.value))}
									className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
								/>
								<IoVolumeHigh size={20} />
							</div>
						</div>

						{/* Speaking Rate */}
						<div className="space-y-2">
							<label className="block text-sm font-medium">Speaking Rate</label>
							<div className="flex items-center gap-2">
								<span className="text-sm">Slower</span>
								<input
									type="range"
									min="-50"
									max="50"
									step="5"
									value={settings.speakingRate}
									onChange={(e) => setSettings('speakingRate', Number(e.target.value))}
									className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
								/>
								<span className="text-sm">Faster</span>
							</div>
						</div>

						{/* Live Conversation Toggle */}
						<div className="flex items-center justify-between">
							<label className="text-sm font-medium">Live Conversation Mode</label>
							<label className="relative inline-flex items-center cursor-pointer">
								<input
									type="checkbox"
									checked={settings.liveConversation}
									onChange={(e) => setSettings('liveConversation', e.target.checked)}
									className="sr-only peer"
								/>
								<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
							</label>
						</div>

						{/* Auto-play Messages Toggle */}
						<div className="flex items-center justify-between">
							<label className="text-sm font-medium">Auto-play Messages</label>
							<label className="relative inline-flex items-center cursor-pointer">
								<input
									type="checkbox"
									checked={settings.autoPlayMessages}
									onChange={(e) => setSettings('autoPlayMessages', e.target.checked)}
									className="sr-only peer"
								/>
								<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
							</label>
						</div>

						{/* Regenerate Lesson Button */}
						<button
							onClick={async () => {
								setExercise(null);
								await fetchExercise();
								setShowSettings(false);
							}}
							className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-200"
						>
							Regenerate New Exercise
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LiveTutorSettingsModal;