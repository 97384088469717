import React, { useRef } from 'react';

interface ListenAndAnswerProps {
	content: {
		audio_transcript: {
			url: string;
			alt: string;
		};
		questions: string[];
	};
	results: any;
	setResults: (results: any) => void;
}

const ListenAndAnswer: React.FC<ListenAndAnswerProps> = ({
	content,
	results,
	setResults
}) => {
	const audioRef = useRef<HTMLAudioElement>(null);

	return (
		<div className="min-h-screen bg-gray-100 p-4">
			<div className="max-w-2xl mx-auto">
				<div className="bg-white rounded-lg shadow-md p-6">
					<h2 className="text-xl font-bold mb-4">Listen and Answer</h2>

					<audio
						ref={audioRef}
						controls
						className="w-full mb-6"
						src={content.audio_transcript.url}
					/>

					<div className="space-y-4">
						{content.questions.map((question, index) => (
							<div key={index} className="flex flex-col">
								<label
									htmlFor={`question-${index}`}
									className="text-sm font-medium text-gray-700 mb-1"
								>
									{question}
								</label>
								<input
									id={`question-${index}`}
									type="text"
									value={results ? results[index] : ''}
									onChange={(e) => {
										setResults((prev: any) => ({
											...prev,
											[question]: e.target.value
										}));
									}}
									className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
									placeholder="Enter your answer"
									autoComplete="off"

								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ListenAndAnswer;