import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { IoCloseCircle, IoAdd } from "react-icons/io5";
import { useServer } from "../hooks/useServer";
import { useAuth } from "../hooks/useAuth";
import { languageFlags, languages } from "../utils/Helpers";

interface RenderChipsProps {
	items: string[];
	setList: any;
}

interface RenderSuggestionsProps {
	suggestions: string[];
	list: string[];
	setList: any;
}

const pageVariants = {
	initial: {
		opacity: 0,
		x: 100,
	},
	animate: {
		opacity: 1,
		x: 0,
	},
	exit: {
		opacity: 0,
		x: -100,
	},
};

const pageTransition = {
	type: "tween",
	duration: 0.3,
};

const suggestedInterests = [
	"Technology", "Travel", "Cooking", "Music", "Sports",
	"Movies", "Art", "Photography", "Fashion", "Science",
];

const OnboardingPage = () => {
	const { currentUser } = useAuth();
	const { updateUser, setUser } = useServer();
	const navigate = useNavigate();

	const [step, setStep] = useState(0);
	const [nativeLanguage, setNativeLanguage] = useState("");
	const [interests, setInterests] = useState([]);
	const [currentInput, setCurrentInput] = useState("");

	const totalSteps = 2;

	const addItem = (item: string, list: string[], setList: any) => {
		if (item && !list.includes(item)) {
			setList((prev: any) => [...prev, item]);
			setCurrentInput("");
		}
	};

	const removeItem = (item: string, setList: any) => {
		setList((prev: any) => prev.filter((i: string) => i !== item));
	};

	const handleNext = () => {
		if (step < totalSteps - 1) {
			setStep(step + 1);
		} else {
			handleSubmit();
		}
	};

	const handleSubmit = async () => {
		if (!nativeLanguage) return;
		const { user } = await updateUser(nativeLanguage, interests);
		if (!user) return;
		setUser(user);
		navigate("/profile");
	};

	const renderChips = ({ items, setList }: RenderChipsProps) => (
		<div className="flex flex-wrap justify-center gap-2 mb-4">
			{items.map((item, index) => (
				<div
					key={index}
					onClick={() => removeItem(item, setList)}
					className="flex items-center gap-1 px-3 py-1 rounded-full bg-blue-500 text-white cursor-pointer"
				>
					<span>{item}</span>
					<IoCloseCircle className="w-5 h-5" />
				</div>
			))}
		</div>
	);

	const renderSuggestions = ({ suggestions, list, setList }: RenderSuggestionsProps) => (
		<div className="flex flex-wrap justify-center gap-2 mb-4">
			{suggestions.map((item, index) => (
				<div
					key={index}
					onClick={() => addItem(item, list, setList)}
					className="flex items-center gap-1 px-3 py-1 rounded-full bg-gray-200 cursor-pointer"
				>
					<span>{item}</span>
					<IoAdd className="w-5 h-5" />
				</div>
			))}
		</div>
	);

	const renderStep = () => {
		switch (step) {
			case 0:
				return (
					<AnimatePresence mode="wait">
						<motion.div
							key="step0"
							initial="initial"
							animate="animate"
							exit="exit"
							variants={pageVariants}
							transition={pageTransition}
							className="flex flex-col items-center w-full max-w-md"
						>
							<h2 className="text-3xl font-bold mb-6 text-blue-600">
								Let's get started!
							</h2>
							<div className="w-full space-y-6">
								<div className="bg-white rounded-lg p-6 shadow-md">
									<h3 className="text-xl font-semibold mb-4">
										What's your native language?
									</h3>
									<select
										value={nativeLanguage}
										onChange={(e) => setNativeLanguage(e.target.value)}
										className="w-full p-2 border rounded-lg"
									>
										<option value="">Select language</option>
										{languages.map((lang) => (
											<option key={lang} value={lang}>
												{languageFlags[lang]} {lang}
											</option>
										))}
									</select>
								</div>
							</div>
						</motion.div>
					</AnimatePresence>
				);
			case 1:
				return (
					<AnimatePresence mode="wait">
						<motion.div
							key="step1"
							initial="initial"
							animate="animate"
							exit="exit"
							variants={pageVariants}
							transition={pageTransition}
							className="flex flex-col items-center w-full max-w-md"
						>
							<h2 className="text-3xl font-bold mb-4 text-blue-600">
								What are your interests?
							</h2>
							<p className="text-center text-gray-600 mb-6">
								We'll recommend personalized content based on your interests to help you learn more effectively.
							</p>
							<div className="bg-white rounded-lg p-6 shadow-md w-full mb-6">
								<input
									type="text"
									value={currentInput}
									onChange={(e) => setCurrentInput(e.target.value)}
									onKeyPress={(e) => e.key === "Enter" && addItem(currentInput, interests, setInterests)}
									placeholder="Type an interest and press Enter"
									className="w-full p-2 border rounded-lg text-lg"
								/>
								<button
									onClick={() => addItem(currentInput, interests, setInterests)}
									className="w-full mt-4 bg-blue-600 text-white py-2 rounded-lg"
								>
									Add Interest
								</button>
							</div>
							{renderChips({ items: interests, setList: setInterests })}
							<div className="w-full">
								<h3 className="text-xl font-semibold mb-4 text-center">
									Suggested interests:
								</h3>
								{renderSuggestions({
									suggestions: suggestedInterests,
									list: interests,
									setList: setInterests,
								})}
							</div>
						</motion.div>
					</AnimatePresence>
				);
			default:
				return null;
		}
	};

	return (
		<div className="min-h-screen bg-gray-100">
			<header className="bg-blue-600 text-white">
				<h1 className="text-center py-6 text-2xl font-bold">
					Welcome to Fluent Future {currentUser?.displayName}!
				</h1>
				<div className="w-full bg-blue-500 h-1">
					<div
						className="h-full bg-blue-300 transition-all duration-300"
						style={{ width: `${((step + 1) / totalSteps) * 100}%` }}
					></div>
				</div>
			</header>
			<main className="p-4">
				<div className="flex flex-col items-center justify-center min-h-full py-8">
					{renderStep()}
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ delay: 0.3 }}
						className="w-full max-w-md"
					>
						<button
							onClick={handleNext}
							disabled={(step === 0 && !nativeLanguage) || (step === 1 && interests.length === 0)}
							className="w-full mt-8 bg-blue-600 text-white py-3 rounded-lg text-lg font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
						>
							{step === totalSteps - 1 ? "Complete Setup" : "Continue"}
						</button>
					</motion.div>
				</div>
			</main>
		</div>
	);
};

export default OnboardingPage;