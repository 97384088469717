import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import {
	takePhoto,
	savePhotoAndCaption,
	getPhotosAndCaptions,
	b64toBlob,
	base64FromPath,
} from "../hooks/useCamera";
import { Image } from "../types";
import { useTranslation } from "react-i18next";
import { useServer } from "../hooks/useServer";
import { getISO_639_1, getLanguage } from "language-flag-colors";

interface ToolCardProps {
	onClick: () => void;
	imageSrc: string;
	altText: string;
	title: string;
}

const ToolCard: React.FC<ToolCardProps> = ({ onClick, imageSrc, altText, title }) => (
	<div
		onClick={onClick}
		className="relative bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105 cursor-pointer group"
	>
		<img
			src={imageSrc}
			alt={altText}
			className="w-full h-48 object-cover"
		/>
		<div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
		<div className="absolute bottom-0 left-0 right-0 p-4">
			<h3 className="text-lg font-semibold text-white">{title}</h3>
		</div>
	</div>
);

const Tools: React.FC = () => {
	const navigate = useNavigate();
	const { user } = useServer();
	const [loading, setLoading] = useState("");
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const [imageData, setImageData] = useState<Image | null>(null);
	const [isQuickTranslateOpen, setIsQuickTranslateOpen] = useState(false);

	const [translatedText, setTranslatedText] = useState("");

	const currentLanguage = user?.nativeLanguage;

	const { captionPhoto, translate } = useServer();

	const handleTranslate = async () => {
		const text = textareaRef.current?.value || "";
		setLoading("Translating...");

		const result = await translate(text, "auto", "en");

		setTranslatedText(result || "Error translating text");
		setLoading("");
	};

	const handleCaptionPhoto = async () => {
		setLoading("Taking photo...");
		const photo = await takePhoto();

		setLoading("Explaining photo...");

		const base64Data = await base64FromPath(photo.webPath!);

		const blob = b64toBlob(base64Data.split(",")[1], "image/jpeg");

		for (let i = 0; i < 3; i++) {
			try {
				const caption = await captionPhoto(blob);

				const savedPhoto = await savePhotoAndCaption(
					photo,
					base64Data,
					`photo-${Date.now()}.jpeg`,
					caption
				);

				setImageData({
					src: savedPhoto.webviewPath!,
					caption: caption,
					filepath: savedPhoto.filepath,
				});

				break;
			} catch (error) {
				console.error("Unable to save photo", error);
			}

			// sleep for 5 seconds
			await new Promise((resolve) => setTimeout(resolve, 5000));
		}
		setLoading("");
	};

	const handleTranslatePhoto = async () => {
		setLoading("Taking photo...");
		const photo = await takePhoto();

		setLoading("Explaining photo...");

		const base64Data = await base64FromPath(photo.webPath!);

		const blob = b64toBlob(base64Data.split(",")[1], "image/jpeg");

		for (let i = 0; i < 3; i++) {
			try {
				const caption = await captionPhoto(blob);

				setLoading("Translating photo...");

				const isoCode = getISO_639_1(currentLanguage);

				if (!isoCode) {
					console.error("Language not found");
					setLoading("");
					return;
				}

				const translation = await translate(
					caption,
					"en",
					isoCode
				);

				console.log("Translation", translation);

				const savedPhoto = await savePhotoAndCaption(
					photo,
					base64Data,
					`photo-${Date.now()}.jpeg`,
					translation
				);

				setImageData({
					src: savedPhoto.webviewPath!,
					caption: translation,
					filepath: savedPhoto.filepath,
				});
				break;
			} catch (error) {
				console.error("Unable to save photo", error);
			}

			// sleep for 5 seconds
			await new Promise((resolve) => setTimeout(resolve, 5000));
		}

		setLoading("");
	};

	return (
		<div className="min-h-screen bg-gray-100">
			<header className="bg-white shadow-md">
				<div className="px-4 py-3 flex justify-between items-center">
					<h1 className="text-xl font-semibold text-gray-800">Tools</h1>
					<button
						onClick={() => navigate('/gallery')}
						className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
					>
						Gallery
					</button>
				</div>
			</header>

			<main className="p-4">
				{loading && (
					<div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
						<div className="text-center">
							<FaSpinner className="w-12 h-12 text-blue-500 animate-spin text-center" />
							<p className="mt-2 text-gray-600">{loading}</p>
							<button
								onClick={() => setLoading("")}
								className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
							>
								Cancel
							</button>
						</div>
					</div>
				)}

				<div className="grid grid-cols-1 md:grid-cols-3 gap-6 m-3">
					<ToolCard
						onClick={handleCaptionPhoto}
						imageSrc="images/img2text.drawio.png"
						altText="Translate Photo to English"
						title="Explain Photo in English"
					/>
					<ToolCard
						onClick={handleTranslatePhoto}
						imageSrc="images/img2text.drawio.png"
						altText={`Translate Photo from English to ${currentLanguage}`}
						title={`Translate Photo from English to ${currentLanguage}`}
					/>
					<ToolCard
						onClick={() => setIsQuickTranslateOpen(true)}
						imageSrc="images/quick_translate.jpg"
						altText="Quick Translate"
						title="Quick Translate"
					/>
				</div>

				{/* Modal for Image Data */}
				{imageData && (
					<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
						<div className="bg-white rounded-lg max-w-lg w-full">
							<div className="p-6 max-h-[80vh] overflow-y-auto">
								<p className="text-gray-700 mb-4">{imageData.caption}</p>
								<img
									src={imageData.src}
									alt="Selected"
									className="w-full rounded-lg shadow-md"
								/>
							</div>
							<button
								onClick={() => setImageData(null)}
								className="w-full p-4 bg-blue-500 text-white hover:bg-blue-600 rounded-b-lg"
							>
								Close
							</button>
						</div>
					</div>
				)}

				{/* Quick Translate Modal */}
				{isQuickTranslateOpen && (
					<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
						<div className="bg-white rounded-lg max-w-lg w-full">
							<div className="p-6">
								<textarea
									ref={textareaRef}
									className="w-full h-40 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
									placeholder="Enter your text here..."
								/>
								<button
									onClick={handleTranslate}
									className="w-full mt-4 p-2 bg-green-500 text-white rounded-md hover:bg-green-600"
								>
									Submit
								</button>
							</div>
							<button
								onClick={() => setIsQuickTranslateOpen(false)}
								className="w-full p-4 bg-blue-500 text-white hover:bg-blue-600 rounded-b-lg"
							>
								Close
							</button>
						</div>
					</div>
				)}

				{/* Translated Text Modal */}
				{translatedText && (
					<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
						<div className="bg-white rounded-lg max-w-lg w-full">
							<div className="p-6">
								<p className="text-gray-800">{translatedText}</p>
							</div>
							<button
								onClick={() => setTranslatedText("")}
								className="w-full p-4 bg-blue-500 text-white hover:bg-blue-600 rounded-b-lg"
							>
								Close
							</button>
						</div>
					</div>
				)}
			</main>
		</div>
	);
};

export default Tools;
