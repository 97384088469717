// Character3D.tsx
import { useRef, useEffect } from 'react';
import { useLoader, useFrame, useThree } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { VRMLoaderPlugin, VRMUtils } from '@pixiv/three-vrm';
import * as THREE from 'three';
import { AnimationMixer } from 'three';
import { loadMixamoAnimation } from './helper';
import { useBlendShapes } from '../../hooks/BlendShapeProvider';

export const Character3D = () => {
	const { blendShapeQueue, popBlendShapeQueue } = useBlendShapes();
	const startTimeRef = useRef<number | null>(null);
	const currentFrameRef = useRef<number>(0);
	const currentBlendShapeIndexRef = useRef<number>(0);

	const meshRef = useRef();
	const mixerRef = useRef<any>();
	const vrmRef = useRef<any>();
	const { scene, camera } = useThree();
	const lookAtTargetRef = useRef(new THREE.Object3D());

	const blinkTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const isBlinkingRef = useRef(false);

	// Add this function to handle blinking
	const handleBlink = () => {

		const blinkDuration = 100; // Duration of blink in milliseconds
		const minInterval = 2000; // Minimum time between blinks
		const maxInterval = 6000; // Maximum time between blinks

		const blink = async () => {
			if (!vrmRef.current || !vrmRef?.current?.expressionManager) return;

			isBlinkingRef.current = true;

			// Close eyes with interpolation
			let start = 0; // Starting value for blink
			const end = 1; // Ending value for blink
			const steps = 10; // Number of steps for interpolation
			const interval = blinkDuration / steps; // Interval time between each step

			for (let i = 0; i <= steps; i++) {
				const value = THREE.MathUtils.lerp(start, end, i / steps);
				vrmRef.current.expressionManager.setValue('blink', value);
				await new Promise(resolve => setTimeout(resolve, interval));
			}

			// Open eyes with interpolation
			for (let i = 0; i <= steps; i++) {
				const value = THREE.MathUtils.lerp(end, start, i / steps);
				vrmRef.current.expressionManager.setValue('blink', value);
				await new Promise(resolve => setTimeout(resolve, interval));
			}

			isBlinkingRef.current = false;

			// Schedule next blink
			const nextBlinkTime = Math.random() * (maxInterval - minInterval) + minInterval;
			blinkTimeoutRef.current = setTimeout(blink, nextBlinkTime);
		};

		blink();
	};


	// Add this effect to initialize and cleanup the blink system
	useEffect(() => {
		if (vrmRef.current?.expressionManager) {
			handleBlink();
		}

		return () => {
			if (blinkTimeoutRef.current) {
				clearTimeout(blinkTimeoutRef.current);
			}
		};
	}, [vrmRef.current]);

	const setBlendShapes = (blendShapeArray: number[]) => {
		if (!vrmRef.current?.expressionManager) return;

		// Initialize all expressions to 0
		const expressions = {
			aa: 0,
			angry: 0,
			blink: 0,
			blinkLeft: 0,
			blinkRight: 0,
			ee: 0,
			happy: 0,
			ih: 0,
			lookDown: 0,
			lookLeft: 0,
			lookRight: 0,
			lookUp: 0,
			neutral: 0,
			oh: 0,
			ou: 0,
			relaxed: 0,
			sad: 0,
			surprised: 0
		};

		// Map ARKit blendshapes to VRM expressions
		if (blendShapeArray.length === 55) {
			// Blink
			if (!isBlinkingRef.current) {
				expressions.blinkLeft = blendShapeArray[0];  // eyeBlinkLeft
				expressions.blinkRight = blendShapeArray[7]; // eyeBlinkRight
				expressions.blink = (expressions.blinkLeft + expressions.blinkRight) / 2;
			}

			// Look directions
			expressions.lookDown = (blendShapeArray[1] + blendShapeArray[8]) / 2;   // eyeLookDownLeft + eyeLookDownRight
			expressions.lookLeft = (blendShapeArray[2] + blendShapeArray[9]) / 2;   // eyeLookInLeft + eyeLookInRight
			expressions.lookRight = (blendShapeArray[3] + blendShapeArray[10]) / 2; // eyeLookOutLeft + eyeLookOutRight
			expressions.lookUp = (blendShapeArray[4] + blendShapeArray[11]) / 2;    // eyeLookUpLeft + eyeLookUpRight

			// Mouth shapes
			expressions.aa = blendShapeArray[17] * 0.5; // jawOpen
			expressions.ih = blendShapeArray[19] * 0.5; // mouthClose
			expressions.ou = blendShapeArray[20];       // mouthFunnel
			expressions.ee = blendShapeArray[21];       // mouthPucker

			// Emotions
			expressions.happy = (blendShapeArray[23] + blendShapeArray[24]) / 2;    // mouthSmileLeft + mouthSmileRight
			expressions.sad = (blendShapeArray[26] + blendShapeArray[27]) / 2;      // mouthFrownLeft + mouthFrownRight
			expressions.angry = (blendShapeArray[42] + blendShapeArray[43]) / 2;    // browDownLeft + browDownRight
			expressions.surprised = blendShapeArray[44] * 0.7;                      // browInnerUp

			// Set neutral and relaxed based on overall facial state
			expressions.neutral = 1 - Math.max(
				expressions.happy,
				expressions.sad,
				expressions.angry,
				expressions.surprised
			);
			expressions.relaxed = expressions.neutral * 0.5;
		}

		// Apply the expressions to the VRM model
		Object.entries(expressions).forEach(([key, value]) => {
			vrmRef.current.expressionManager.setValue(key, Math.min(Math.max(value, 0), 1));
		});
	};

	useFrame((state, delta) => {
		if (mixerRef.current) {
			mixerRef.current.update(delta);
		}
		if (vrmRef.current) {
			vrmRef.current.update(delta);
		}

		if (vrmRef.current) {
			const vrmPosition = vrmRef.current.scene.position;
			const cameraPosition = camera.position;

			const angle = Math.atan2(
				camera.position.x - vrmPosition.x,
				camera.position.z - vrmPosition.z
			);
			vrmRef.current.scene.rotation.y = angle; // Add Math.PI if model is facing opposite direction
		}

		// Update lookAt target to follow the camera position
		if (vrmRef.current?.lookAt && lookAtTargetRef.current) {
			lookAtTargetRef.current.position.copy(camera.position);
			vrmRef.current.lookAt.target = lookAtTargetRef.current;
		}

		// Handle blendshape animation (same logic as in your code)
		if (blendShapeQueue.length > 0) {
			if (startTimeRef.current === null) {
				startTimeRef.current = state.clock.elapsedTime;
				currentBlendShapeIndexRef.current = 0;
			}

			const currentFrame = blendShapeQueue[0];
			const elapsed = state.clock.elapsedTime - startTimeRef.current;

			if (currentFrame?.frameIndex === -1) {
				//console.log('End frame');
				setBlendShapes(new Array(55).fill(0));
				startTimeRef.current = null;
				popBlendShapeQueue();
				return;
			}

			/*console.log('Processing frame:', currentFrame?.frameIndex,
				'Blend Shape Index:', currentBlendShapeIndexRef.current,
				'Elapsed:', elapsed * 1000);*/

			if (currentFrame && elapsed * 1000 >= currentFrame.timestamp) {
				if (currentBlendShapeIndexRef.current < currentFrame.blendShapes.length) {
					setBlendShapes(currentFrame.blendShapes[currentBlendShapeIndexRef.current]);
					currentBlendShapeIndexRef.current++;
				} else {
					popBlendShapeQueue();
					startTimeRef.current = state.clock.elapsedTime;
					currentBlendShapeIndexRef.current = 0;
				}
			}
		}
	});

	// Load the VRM model
	const gltf = useLoader(
		GLTFLoader,
		'sample.vrm',
		(loader) => {
			loader.register((parser) => {
				return new VRMLoaderPlugin(parser);
			});
		}
	);

	useEffect(() => {
		const light = new THREE.DirectionalLight(0xffffff, Math.PI);
		light.position.set(1.0, 1.0, 1.0).normalize();
		scene.add(light);

		const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
		scene.add(ambientLight);

		return () => {
			scene.remove(light);
			scene.remove(ambientLight);
		};
	}, [scene]);



	useEffect(() => {
		if (!gltf) return;

		const vrm = gltf.userData.vrm;
		if (!vrm) return;

		// Clone the VRM model
		VRMUtils.removeUnnecessaryJoints(gltf.scene);

		// Set current VRM model
		vrmRef.current = vrm;
		meshRef.current = vrm.scene;



		// Create animation mixer
		const mixer = new AnimationMixer(vrm.scene);
		mixerRef.current = mixer;

		// Load and process the Mixamo animation
		loadMixamoAnimation('animations/idle.fbx', vrm)
			.then((animationClip) => {
				const action = mixer.clipAction(animationClip);
				action.play();
			})
			.catch((error) => {
				console.error('Error loading animation:', error);
			});

		// Disable frustum culling
		vrm.scene.traverse((obj: any) => {
			obj.frustumCulled = false;
		});

		// Initial position and rotation
		vrm.scene.scale.set(1, 1, 1);
		vrm.scene.position.set(-0.05, -1.3, 0);
		vrm.scene.rotation.set(0, 0, 0);

		// Add to scene
		scene.add(vrm.scene);

		return () => {
			scene.remove(vrm.scene);
			mixer.stopAllAction();
			mixer.uncacheRoot(vrm.scene);
		};
	}, [gltf, scene]);

	useFrame((state, delta) => {
		if (mixerRef.current) {
			mixerRef.current.update(delta);
		}
		if (vrmRef.current) {
			vrmRef.current.update(delta);
		}
	});

	return null; // We're managing the scene manually in the effect
};

export default Character3D;