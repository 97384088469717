import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';

const TermsAndConditions: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div className="min-h-screen flex flex-col">
			<header className="bg-white border-b">
				<div className="px-4 h-14 flex items-center">
					<button
						onClick={() => navigate(-1)}
						className="flex items-center text-blue-600 p-2"
					>
						<IoArrowBack className="text-xl" />
						<span className="ml-1">Back</span>
					</button>
					<h1 className="text-lg font-semibold ml-2">Terms and Conditions</h1>
				</div>
			</header>

			<main className="flex-1 overflow-auto p-4">
				<div className="max-w-3xl mx-auto">
					<h1 className="text-2xl font-bold mb-4">Terms and Conditions for Fluent Future</h1>

					<div>
						<p className="mb-4">
							Last updated: 2024-10-08
						</p>

						<p className="mb-4">
							Please read these Terms and Conditions carefully before using the Fluent Future application.
						</p>

						<h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
						<p className="mb-4">
							By accessing or using Fluent Future, you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms, you may not access the application.
						</p>

						<h2 className="text-xl font-semibold mb-2">2. Use of the Application</h2>
						<p className="mb-4">
							Fluent Future is a language learning application designed for newcomers in Canada. You agree to use the application only for its intended purpose and in compliance with all applicable laws and regulations.
						</p>

						{/* ... Rest of the content remains the same, just removed IonText wrapper ... */}

						<h2 className="text-xl font-semibold mb-2">10. Contact Us</h2>
						<p className="mb-4">
							If you have any questions about these Terms, please contact us at:
						</p>
						<p className="mb-4">
							Fluent Future<br />
							Email: info@fluentfuture.ca<br />
						</p>
					</div>
				</div>
			</main>
		</div>
	);
};

export default TermsAndConditions;