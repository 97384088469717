
import { MdSettings, MdPauseCircle, MdChat, MdClose, MdWarning, MdSend, MdStopCircle, MdMic } from 'react-icons/md';
import { FaExclamation } from 'react-icons/fa';
import { useState, useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Character3D from './Character3D';
import Whiteboard from './Whiteboard';
import { useAzure } from '../../hooks/useAzure';
import { useServer } from '../../hooks/useServer';
import { Exercise, Message, LiveTutorSettings } from '../../types';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { split } from "sentence-splitter";
import { reduceByRole } from '../../utils/Helpers';
import LiveTutorSettingsModal from './LiveTutorSettings';
import { useLoading } from '../../hooks/LoadingProvider';
import { useToast } from '../../hooks/ToastProvider';
declare global {
	interface Window {
		vadit: (
			onSpeechStart: () => void,
			onSpeechEnd: () => void
		) => Promise<any>;
	}
}

interface SpeechState {
	fullText: string;
	currentWordIndex: number;
	words: string[];
	wordBoundaries: Array<{
		text: string;
		textOffset: number;
		duration: number;
		audioOffset: number;
	}>;
}

const exerciseMapper = {
	"1b": "Word Picture Match",
	"2a": "Fill in the Blank",
	"4b": "Listen and Answer",
	"5a": "Roleplay Conversation",
	"6a": "Read and Answer",
} as { [key: string]: string; };

const LiveTutor: React.FC = () => {
	// Basic UI states
	const [isPaused, setIsPaused] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [showMessageLog, setShowMessageLog] = useState(false);
	const [showSettings, setShowSettings] = useState(false);

	// Message handling states
	const [messages, setMessages] = useLocalStorage<Message[]>(`messages`, []);
	const messagesRef = useRef<Message[]>(messages);

	const [suggestion, setSuggestion] = useState<string | null>(null);
	const [messageQueue, setMessageQueue] = useState<string[]>([]);
	const messageQueueRef = useRef<string[]>([]);
	const [currentSpeech, setCurrentSpeech] = useState("");

	// Recording states
	const [isRecording, setIsRecording] = useState(false);
	const mediaRecorderRef = useRef<MediaRecorder | null>(null);
	const clearSpeechTimeout = useRef<NodeJS.Timeout | null>(null);

	// App state
	const [state, setState] = useState("start");
	const [exercise, setExercise] = useLocalStorage<Exercise | null>("currentExercise", null);
	const [results, setResults] = useState<any>(null);
	const resultsRef = useRef<any>(results);
	const vadRef = useRef<any>(null);


	// Providers
	const { stopTextAsync, speakTextAsync } = useAzure();
	const { user, transcribe, chat, getExercise, getExerciseFeedback, postExercise } = useServer();
	const { showToast } = useToast();

	// Settings
	const [liveTutorSettings, setLiveTutorSettings] = useLocalStorage<LiveTutorSettings>("liveTutorSettings", {
		volume: 100,
		liveConversation: false,
		autoPlayMessages: true,
		speakingRate: -20,
	});

	const { showLoading, hideLoading } = useLoading();

	useEffect(() => {
		messagesRef.current = messages;
		messageQueueRef.current = messageQueue;
		resultsRef.current = results;
	}, [messages, messageQueue, results]);




	const startRecord = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: true,
			});
			const mediaRecorder = new MediaRecorder(stream);
			mediaRecorderRef.current = mediaRecorder;

			const audioChunks: Blob[] = [];
			mediaRecorder.ondataavailable = (event) => {
				audioChunks.push(event.data);
			};

			mediaRecorder.onstop = async () => {
				const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
				const transcription = await transcribe(audioBlob);
				if (!transcription) {
					console.error("Error transcribing audio");
					return;
				}

				handleChat(transcription);
				stream.getTracks().forEach((track) => track.stop());
			};

			mediaRecorder.start();
			setIsRecording(true);
		} catch (error) {
			console.error("Error starting recording:", error);
		}
	};

	const stopRecord = () => {
		if (mediaRecorderRef.current) {
			mediaRecorderRef.current.stop();
			setIsRecording(false);
		}
	};

	useEffect(() => {
		const initializeVAD = async () => {
			try {
				if (typeof window.vadit === 'function') {
					window.vadit(() => setIsRecording(true), () => setIsRecording(false)).then((vad: any) => {
						console.log('VAD initialized');
						vadRef.current = vad;
					});
				}

			} catch (error) {
				console.error("Error initializing VAD:", error);
				// Handle the error (e.g., show an error message to the user)
			}
		};
		initializeVAD();
		return () => {
			if (vadRef.current) {
				vadRef.current.pause();
			}
		};
	}, []);


	useEffect(() => {

		if (vadRef.current) {
			if (liveTutorSettings.liveConversation) {
				console.log("Starting VAD");
				vadRef.current.start();
			} else {
				console.log("Stopping VAD");
				vadRef.current.pause();
			}
		}
	}, [liveTutorSettings.liveConversation]);

	useEffect(() => {
		// clear everything
		setSuggestion(null);
		setCurrentSpeech("");
		setMessageQueue([]);

		if (isRecording) {
			startRecord();
		} else {
			stopRecord();
		}
	}, [isRecording]);

	useEffect(() => {
		setSuggestion(null);
	}, [state, results, messages]);

	// set interval for checking on results every 20 seconds
	useEffect(() => {
		const interval = setInterval(async () => {
			if (exercise && state === "exercise" && !suggestion && !currentSpeech) {
				handleChat("Do you see my work? I think I need some help.", false);
			}
		}, 30000);

		return () => clearInterval(interval);
	}, [state, suggestion, currentSpeech]);


	const handleSpeak = async (message: string) => {
		// Break the message into sentences
		const sentences = split(message).map((sentence) => sentence.raw).filter((sentence) => sentence.trim().length > 0);

		// Pop first
		const currentSentence = sentences.shift();

		// Add the to message queue
		setMessageQueue([...messageQueue, ...sentences]);

		if (currentSentence) {
			const newMessages = [...messagesRef.current, { role: 'assistant', content: currentSentence }] as Message[];
			setMessages(newMessages);
			setCurrentSpeech(currentSentence);
			clearSpeechTimeout.current && clearTimeout(clearSpeechTimeout.current);

			stopTextAsync();

			// Speak the current sentence
			await speakTextAsync({
				text: currentSentence,
				rate: liveTutorSettings.speakingRate,
				voice: 'en-US-AvaNeural',
				updateBlendShapes: true,
				cb: () => {
					if (messageQueueRef.current.length === 0) {
						clearSpeechTimeout.current = setTimeout(() => {
							setCurrentSpeech('');
						}, 5000);
					}
				}
			});
		}
	}

	const handleMessageQueue = async () => {
		const newMessageQueue = [...messageQueue];
		const nextMessage = newMessageQueue.shift();
		if (nextMessage) {
			handleSpeak(nextMessage);
			// remove the message from the queue
			setMessageQueue(newMessageQueue);
		}
	}

	const clearMessageQueue = () => {
		setMessageQueue([]);
		setCurrentSpeech('');
	}


	const handleChat = async (message: string, add_to_log: boolean = true) => {
		let newMessages = [...messagesRef.current] as Message[];

		if (message) {
			newMessages = [...messagesRef.current, { role: 'user', content: message }] as Message[];
			if (add_to_log) setMessages(newMessages);
		}

		let system_message;
		if (state === "start") {
			if (exercise) {
				system_message = `You are a friendly tutor named Sarah. You are teaching English to beginners.

# THE USER's LANGUAGE PROFILE:
${user?.profile?.updatedProfile || 'No profile available'}

# THE USER CURRENTLY SEES:
A screen with the lesson type (${exercise?.exerciseType}) and total questions with a 'Start Now' button.

# INSTRUCTIONS:
- First have smalltalk with the user as a tutor would do.
- Afterwards, you can encourage them to start the exercise.
- Keep your responses short and conversational.`;


			} else {
				system_message = `You are a friendly tutor named Sarah. You are teaching English to beginners.

# THE USER's LANGUAGE PROFILE:
${user?.profile.updatedProfile || 'No profile available'}

# THE USER CURRENTLY SEES:
An empty whiteboard. You are creating a new exercise for the user at the moment. It will be ready soon.

# INSTRUCTIONS:
- Provide full sentences without placeholders.
- Your responses will be spoken so do not use any special characters or symbols.
- Have smalltalk with the user as a tutor would do.
- Keep your responses short and conversational.`;
			}


			// Handle chat message

		} else if (state === "exercise") {
			system_message = `You are a friendly tutor named Sarah. You are teaching English to beginners. They are currently doing a ${exercise?.exerciseType ? exerciseMapper[exercise.exerciseType] : ''} exercise.

# THE USER's LANGUAGE PROFILE:
${user?.profile.updatedProfile || 'No profile available'}

# THE USER CURRENTLY SEES THIS EXERCISE:
Questions:
${JSON.stringify(exercise, null, 2)}

# THE USER'S ANSWERS SO FAR:
${JSON.stringify(resultsRef.current, null, 2)}

${!resultsRef.current && `Note that 'null' results means the user has not answered that question yet.`}

# INSTRUCTIONS:
- Try to be conversational. Don't try to come up with new exercises.
- Provide full sentences without placeholders.
- Your responses will be spoken so do not use any special characters or symbols.
- Provide simple hints and feedback to the user.
- Encourage them to complete the exercise.
- Ask them guiding questions if they are stuck.
- Keep your responses short and conversational.`;
		}

		// reduce 
		const reducedMessages = reduceByRole(newMessages);

		// Send chat message
		const messagesToSend = [
			...reducedMessages.slice(-10),
			{ role: "system", content: system_message },
		];

		const response = (await chat(messagesToSend)).trim()

		if (!response) {
			console.error("Error sending chat message");
			return;
		}

		if ((message && add_to_log) || state === "start") {
			handleSpeak(response);
		} else {
			// Add to message queue
			setSuggestion(response);
		}
	};

	//const exercisesWithFeedback = [];

	const handleCheck = async () => {
		// Handle user submission
		if (exercise) {
			handleChat("Can you check my answers? Give me specific feedback on how I did on the exercise.", false);
			/*if (exercisesWithFeedback.includes(exercise.exerciseType)) {
				const feedback = await getExerciseFeedback(exercise, results, messages);
				console.log(feedback);
			} else {
				
			}*/
		}
	};

	const handleSubmit = async () => {
		if (exercise) {
			try {
				showLoading("Submitting exercise...");
				const reducedMessages = reduceByRole(messagesRef.current);
				const newExercise = await postExercise(exercise, results, reducedMessages);
				setExercise(newExercise);
				setResults(null);
				setState("start");
			} catch (error) {
				showToast("Error submitting exercise. Please try again later.");
			} finally {
				hideLoading();
			}
		}
	}



	const help = async (content: string) => {
		// Handle user help request
		handleChat(`Can you help me with this: ${content}`, false);
	};


	// Mock function to simulate fetching lesson data
	const fetchExercise = async () => {
		try {
			console.log("Fetching new exercise...");
			showLoading("Loading new exercise...");
			const reducedMessages = reduceByRole(messagesRef.current);
			//const lesson = await getLesson(); // Assuming getLesson fetches the lesson object
			//setLessonType(lesson.type); // Set lesson type based on fetched lesson
			const newExercise = await getExercise(reducedMessages);
			console.log(newExercise);
			setState("start");
			setResults(null);
			setExercise(newExercise);
		} catch (error) {
			showToast("Error loading exercise. Please try again later.");
		} finally {
			hideLoading();
		}
	};

	const handleSuggestion = async () => {
		if (suggestion) {
			handleSpeak(suggestion);
			setSuggestion(null);
		}
	}

	const cleanExerciseContent: any = (content: any) => {
		// check if array
		if (Array.isArray(content)) {
			return content.map((item) => cleanExerciseContent(item));
		} else if (typeof content === 'object') {
			console.log(content)
			// check if there is an image in content
			if (content.image) {
				content.image = "A picture of " + content.image.alt;
			}

			if (content.audio_transcript) {
				content.audio_transcript = "An audio track of " + content.audio_transcript.alt;
			}

			return content;
		}
	}


	const startExercise = () => {
		if (!exercise) {
			showToast("Exercise not loaded yet...");
			return;
		}

		const exerciseContent = JSON.parse(JSON.stringify(exercise.content));

		// clean the exercise
		const cleanedExerciseContent = cleanExerciseContent(exerciseContent);

		// add to system message to messages
		const newMessages = [{
			role: 'system', content: `The user just started a new exercise: ${exercise?.exerciseType}
With the following content:
${JSON.stringify(cleanedExerciseContent, null, 2)}`
		}] as Message[];
		setMessages(newMessages);
		setState("exercise");
	}

	useEffect(() => {
		if (!exercise) {
			// wait for 


			fetchExercise().catch(console.error);
		} else {
			hideLoading();
		}

		handleChat("Hi", false);
	}, []); // Fetch lesson on mount

	return (
		<div className="min-h-screen bg-gradient-to-br from-gray-900 to-slate-900 overflow-y-hidden">
			<main className="relative flex-1 h-screen">
				{/* Whiteboard Container with subtle animation */}
				<div className="w-full h-[calc(100vh-2rem)] flex justify-center items-center animateFadeIn">
					<Whiteboard
						width={window.innerWidth - 80}
						height={window.innerHeight - 150}
						lesson="Grammar"
						exercise={exercise}
						state={state}
						startExercise={startExercise}
						results={results}
						setResults={setResults}
						help={help}
						handleCheck={handleCheck}
						handleSubmit={handleSubmit}

					/>
				</div>

				{/* 3D Character Overlay */}
				<div className={`absolute ${showMessageLog ? 'right-96' : 'right-14'} bottom-0 h-1/2  z-10 flex items-end ${isHovered ? 'opacity-40' : ''}`}>
					<Canvas
						style={{
							background: 'transparent',
						}}
						camera={{
							position: [0, 0.2, 1.3],
							fov: 30,
							near: 0.1,
							far: 1000,
							zoom: 1,
						}}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
						<ambientLight intensity={0.3} />

						<Character3D />
					</Canvas>

					{/* Notification Icon */}
					{suggestion && (
						<button
							className="absolute bottom-[370px] right-20 flex items-center justify-center bg-red-500 text-white font-bold rounded-full w-8 h-8 text-sm z-20 hover:scale-110 transition-transform duration-200 cursor-pointer"
							onClick={handleSuggestion}
						>
							<FaExclamation />
						</button>
					)}

					{/* Speech Bubble UI */}
					{currentSpeech && (
						<div className="absolute bottom-[350px] right-40 z-30 transition-all duration-300 ease-in-out">
							<div className="relative bg-white text-black p-4 w-64 rounded-lg shadow-lg border border-gray-200 animate-fadeIn">
								{/* Close button */}
								<button
									onClick={clearMessageQueue}
									className="absolute top-[-12px] right-[-12px] bg-gray-100 text-gray-600 hover:bg-gray-200 
  rounded-full w-7 h-7 flex items-center justify-center shadow-sm border border-gray-300 
  transition-all duration-200 hover:scale-105"
								>
									<MdClose className="text-lg" />
								</button>

								<p className="text-sm leading-relaxed min-h-[2em]">{currentSpeech}</p>
								{messageQueue.length > 0 && (
									<div className="flex justify-end mt-2">
										<button
											onClick={handleMessageQueue}
											className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors text-sm"
										>
											Next
										</button>
									</div>
								)}
								<div className="absolute -bottom-2 right-8 w-4 h-4 bg-white transform rotate-45 border-r border-b border-gray-200"></div>
							</div>
						</div>
					)}


				</div>

				{/* Control Buttons */}
				<div className="fixed bottom-4 right-4 flex flex-col gap-2">
					<button
						onClick={() => setShowMessageLog(!showMessageLog)}
						className="p-3 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors"
					>
						<MdChat className="text-xl" />
					</button>
					<button
						onClick={() => setShowSettings(true)}
						className="p-3 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors"
					>
						<MdSettings className="text-xl" />
					</button>
					<button
						onClick={() => setIsPaused(!isPaused)}
						className={`p-3 rounded-full text-white transition-colors ${isPaused ? 'bg-green-500 hover:bg-green-600' : 'bg-yellow-500 hover:bg-yellow-600'}`}
					>
						<MdPauseCircle className="text-xl" />
					</button>
				</div>

				{/* Message Log Panel */}
				{
					showMessageLog && (
						<div className="absolute right-20 bottom-40 w-80 h-[60vh] bg-white rounded-lg shadow-2xl z-20 flex flex-col overflow-hidden border border-gray-200">
							<div className="flex justify-between items-center px-4 py-3 bg-gray-50 border-b">
								<h2 className="text-lg font-semibold text-gray-700">Message Log</h2>
								<button
									onClick={() => setShowMessageLog(false)}
									className="text-gray-500 hover:text-gray-700"
								>
									<MdClose className="text-2xl" />
								</button>
							</div>

							<div className="flex-1 overflow-y-auto p-4 space-y-3">
								{messages.filter((message) => message.role !== 'system').map((message, index) => (
									<div
										key={index}
										className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
									>
										<div
											className={`max-w-[80%] p-3 rounded-lg ${message.role === 'user'
												? 'bg-blue-500 text-white rounded-br-none'
												: 'bg-gray-100 text-gray-800 rounded-bl-none'
												}`}
										>
											<p className="text-sm">{message.content}</p>
										</div>
									</div>
								))}
							</div>

							<div className="p-3 bg-gray-50 border-t">
								<form
									className="flex items-center gap-2"
									onSubmit={(e: any) => {
										e.preventDefault();
										const input = e.target.elements.messageInput;
										const message = input.value.trim();
										if (message) {
											handleChat(message);
											input.value = '';
										}
									}}
								>
									<input
										autoComplete='off'
										type="text"
										name="messageInput"
										placeholder="Type your message..."
										className="flex-1 px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:border-blue-500"
									/>
									<button
										type="submit"
										className="p-2 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors"
									>
										<MdSend className="text-xl" />
									</button>
								</form>
							</div>
						</div>
					)
				}

				{/* Settings Modal */}
				<LiveTutorSettingsModal
					showSettings={showSettings}
					setShowSettings={setShowSettings}
					setExercise={setExercise}
					fetchExercise={fetchExercise}
					settings={liveTutorSettings}
					setSettings={(key, value) => setLiveTutorSettings({ ...liveTutorSettings, [key]: value })}
				/>

				<div className="fixed bottom-8 left-1/2 transform -translate-x-1/2">
					<button
						onClick={() => setIsRecording(!isRecording)}
						className={`p-6 rounded-full text-white ${isRecording ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'
							} transition-colors`}
					>
						{isRecording ?
							<MdStopCircle className="text-4xl" /> :
							<MdMic className="text-4xl" />
						}
					</button>
				</div>
			</main >
		</div >
	);
}

export default LiveTutor;