import { useAzure } from "../../hooks/useAzure";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import React, { Fragment, useEffect, useState, useRef } from "react";
import {
	IoStop,
	IoInformationCircleOutline,
	IoSchoolOutline,
	IoMic,
	IoVolumeHigh,
	IoCheckmark
} from "react-icons/io5";

interface PracticeWordsProps {
	lessons: any,
	sessionId: string
	current: number
}

const PracticeWords: React.FC<PracticeWordsProps> = ({ lessons, sessionId, current }) => {
	const { speakTextAsync, stopTextAsync, startPronunciationAssessment, stopPronunciationAssessment } = useAzure();

	const [showLessonModal, setShowLessonModal] = useState(false);
	const [selectedLesson, setSelectedLesson] = useState<any>(null);

	const [praciticingWord, setPracticingWord] = useState<number>(-1);
	const [practiceWordPlaying, setPracticeWordPlaying] = useState<number>(-1);
	const [practicedWords, setPracticedWords] = useLocalStorage<number[]>(`${sessionId}-${current}-practicedWords`, []);

	const practicedWordsRef = useRef(practicedWords);
	const praciticingWordRef = useRef(praciticingWord);

	useEffect(() => {
		praciticingWordRef.current = praciticingWord;
	}, [praciticingWord]);

	useEffect(() => {
		practicedWordsRef.current = practicedWords;
	}, [practicedWords]);

	useEffect(() => {
		setPracticedWords([]);
	}, [current, sessionId]);

	const getLessonModal = () => {
		if (!selectedLesson) return null;

		const score = selectedLesson.score;
		const lesson = selectedLesson.lesson.content;
		const metric = selectedLesson.phoneme;
		const words = selectedLesson.lesson.words;

		return (
			<div className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center ${showLessonModal ? '' : 'hidden'}`}>
				<div className="bg-white rounded-lg p-6 max-w-3xl mx-auto h-[90vh] flex flex-col w-[90%]">
					<div className="flex justify-between items-center mb-4">
						<h2 className="text-2xl font-bold text-blue-600">{metric}</h2>
						<p className="font-semibold text-lg mb-6">
							Score: <span className="text-blue-600">{score.toFixed(2)}</span>
						</p>
					</div>
					<div className="flex-grow overflow-y-auto">
						<div className="lesson-content mb-6">
							{lesson.split('\n').map((paragraph: string, index: number) => (
								<p key={index} className="mb-4 text-lg leading-relaxed">
									{paragraph}
								</p>
							))}
						</div>
						<h3 className="font-semibold text-xl mb-4 text-purple-600">
							Practice Words:
						</h3>
						<ul className="space-y-4 mb-6">
							{words.map((word: string, index: number) => (
								<li key={index} className="flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-sm">
									<span className="text-lg font-medium">{word}</span>
									<div className="space-x-2">
										{practicedWords.includes(index) ? (
											<button className="px-4 py-2 bg-green-500 text-white rounded-md flex items-center space-x-2 opacity-50 cursor-not-allowed">
												<IoCheckmark />
												<span>Done</span>
											</button>
										) : praciticingWord === index ? (
											<button
												className="px-4 py-2 bg-red-500 text-white rounded-md flex items-center space-x-2"
												onClick={() => {
													setPracticingWord(-1);
													stopPronunciationAssessment();
												}}
											>
												<div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full" />
												<IoStop />
											</button>
										) : (
											<button
												className="px-4 py-2 bg-blue-500 text-white rounded-md flex items-center space-x-2"
												onClick={() => {
													if (praciticingWord !== -1) {
														stopPronunciationAssessment();
													}
													setPracticingWord(index);
													const word = selectedLesson.lesson.words[index];
													const callbacks = {} as any;

													callbacks.recognizing = (s: any, e: any) => {
														console.log(`RECOGNIZING: Text=${e.result.text}`);
													};

													callbacks.results = async (words: any, scores: any, blob: Blob) => {
														const tempPracticedWords = [...practicedWordsRef.current];
														tempPracticedWords.push(praciticingWordRef.current);
														setPracticedWords(tempPracticedWords);
														setPracticingWord(-1);
														stopPronunciationAssessment();
													}

													startPronunciationAssessment(word, callbacks);
												}}
											>
												<IoMic />
												<span>Try</span>
											</button>
										)}
										<button
											className="px-4 py-2 bg-purple-500 text-white rounded-md flex items-center space-x-2"
											onClick={() => {
												speakTextAsync({
													text: word,
													cb: () => {
														setPracticeWordPlaying(-1);
													},
												});
											}}
										>
											<IoVolumeHigh />
										</button>
									</div>
								</li>
							))}
						</ul>
					</div>
					<button
						className="w-full py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors mt-4"
						onClick={() => setShowLessonModal(false)}
					>
						Finish
					</button>
				</div>
			</div>
		);
	};

	return (
		<Fragment>
			{lessons && (
				<div className="mt-6">
					<h3 className="text-lg font-semibold mb-2">
						Lessons
					</h3>
					<div className="space-y-2">
						{lessons.map((lesson: any, index: number) => (
							<div
								key={index}
								className="bg-white rounded-lg shadow-md cursor-pointer hover:bg-gray-50 transition-colors"
								onClick={() => {
									setSelectedLesson(lesson);
									setShowLessonModal(true);
								}}
							>
								<div className="flex items-center justify-between p-3">
									<div className="flex items-center space-x-3">
										<IoSchoolOutline className="text-2xl text-blue-600" />
										<div>
											<h4 className="font-medium">
												{lesson.phoneme}
											</h4>
											<p className="text-sm text-gray-500">
												Score: {lesson.score.toFixed(2)}
											</p>
										</div>
									</div>
									<IoInformationCircleOutline />
								</div>
							</div>
						))}
					</div>
				</div>
			)}
			{getLessonModal()}
		</Fragment>
	);
};

export default PracticeWords;