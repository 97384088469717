import React, { useState } from 'react';
import { FaBookReader, FaQuestionCircle, FaPencilAlt } from 'react-icons/fa';

interface ReadAndAnswerProps {
	results: any;
	setResults: (results: any) => void;
	content: {
		text: string;
		questions: string[];
	};
}

const ReadAndAnswer: React.FC<ReadAndAnswerProps> = ({ results, setResults, content }) => {
	const handleAnswerChange = (index: number, value: string) => {
		setResults((prev: any) => ({
			...prev,
			[content.questions[index]]: value
		}));
	};

	return (
		<div className="max-w-4xl mx-auto p-6 space-y-8">
			{/* Reading Section */}
			<div className="bg-white rounded-lg shadow-md p-6">
				<div className="flex items-center gap-2 mb-4">
					<FaBookReader className="text-blue-600 text-xl" />
					<h2 className="text-xl font-semibold text-gray-800">Reading Passage</h2>
				</div>
				<p className="text-gray-700 leading-relaxed">
					{content.text}
				</p>
			</div>

			{/* Questions Section */}
			<div className="space-y-6">
				<div className="flex items-center gap-2 mb-4">
					<FaQuestionCircle className="text-blue-600 text-xl" />
					<h2 className="text-xl font-semibold text-gray-800">Questions</h2>
				</div>

				{content.questions.map((question, index) => (
					<div
						key={index}
						className="bg-white rounded-lg shadow-md p-6"
					>
						<div className="flex flex-col space-y-4">
							<div className="flex items-start gap-4">
								<span className="bg-blue-100 text-blue-600 font-semibold rounded-full w-8 h-8 flex items-center justify-center">
									{index + 1}
								</span>
								<p className="text-gray-700 flex-1">{question}</p>
							</div>

							<div className="flex items-center gap-2 ml-12">
								<FaPencilAlt className="text-gray-400" />
								<textarea
									value={results ? results[question] : ''}
									onChange={(e) => handleAnswerChange(index, e.target.value)}
									className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
									rows={3}
									placeholder="Write your answer here..."
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default ReadAndAnswer;