import React, { useState, useRef, useEffect } from 'react';
import { FaPlay, FaMicrophone, FaVolumeLow, FaCheck } from 'react-icons/fa6';
import { useAzure } from '../../../../hooks/useAzure';
import { RecognitionCallbacks } from '../../../../types';
import { identifyPronunciationProblems } from '../../../../utils/Helpers';

interface DialogueLine {
	role: string;
	line: string;
}

interface RoleplayConversationProps {
	dialogue: DialogueLine[];
	setResults: (results: any) => void;
}

const RoleplayConversation: React.FC<RoleplayConversationProps> = ({ dialogue, setResults }) => {
	const { speakTextAsync, startPronunciationAssessment, stopPronunciationAssessment } = useAzure();
	const [userRole, setUserRole] = useState<string>('');
	const [currentLineIndex, setCurrentLineIndex] = useState<number>(-1);
	const currentLineRef = useRef<number>(0);
	const [isRecording, setIsRecording] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		if (!dialogue) return;

		// Randomly assign a role to the user
		const index = dialogue.length % 3;
		const roles = dialogue.map(d => d.role);
		const randomRole = roles[index];
		setUserRole(randomRole);
	}, [dialogue]);

	useEffect(() => {
		if (isRecording) {
			startRecognition();
		} else {
			stopPronunciationAssessment();
		}
	}, [isRecording]);

	useEffect(() => {
		if (currentLineIndex >= 0 && currentLineIndex < dialogue.length) {
			currentLineRef.current = currentLineIndex;
			if (dialogue[currentLineIndex].role !== userRole) {
				playCurrentLine();
			}
		}
	}, [currentLineIndex]);


	const playCurrentLine = async () => {
		if (currentLineIndex >= 0 && currentLineIndex < dialogue.length) {
			setIsPlaying(true);
			if (dialogue[currentLineIndex].role !== userRole) {
				await speakTextAsync({
					text: dialogue[currentLineIndex].line,
					rate: 0,
					cb: () => {
						setIsPlaying(false);
						handleNext();
					},
				});
			}
		}
	};

	const startRecognition = async () => {
		console.log("Starting recognition");
		//setDisplayText("Listening...");
		const callbacks = {} as RecognitionCallbacks;

		callbacks.recognizing = (s: any, e: any) => {
			//setDisplayText(`${e.result.text}`);
			console.log(e.result.text);
		};

		callbacks.results = async (words: any, scores: any, blob: Blob) => {
			//const audiofileName = `${stateRef.current.sessionId}-${currentRef.current}`;

			//await saveBlobToStorage(audiofileName, blob);

			const newResults = {
				words,
				scores,
				timestamp: new Date().toISOString(),
				//audio: audiofileName,
			};

			const results = identifyPronunciationProblems(newResults.words) || "No pronunciation problems found";
			setResults((prev: any) => ({ ...prev, [currentLineRef.current]: results }));
			handleNext();
		}
		startPronunciationAssessment(
			dialogue[currentLineIndex].line,
			callbacks
		)
	};

	const handleNext = () => {
		// if was recording
		if (isRecording) {
			setIsRecording(false);
		}
		setCurrentLineIndex(prev => prev + 1);
	};

	return (
		<div className="max-w-2xl mx-auto p-6">
			<div className="mb-6 text-center">
				<h2 className="text-xl font-bold mb-2">Your Role: {userRole}</h2>
				{currentLineIndex === -1 && (
					<button
						onClick={() => setCurrentLineIndex(0)}
						className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full flex items-center justify-center gap-2 mx-auto"
					>
						<FaPlay /> Start Conversation
					</button>
				)}
			</div>

			<div className="space-y-4">
				{dialogue.map((line, index) => (
					<div
						key={index}
						className={`p-4 rounded-lg ${index === currentLineIndex
							? 'bg-yellow-100 border-2 border-yellow-300'
							: 'bg-gray-100'
							} ${index > currentLineIndex ? 'opacity-50' : ''}`}
					>
						<div className="flex justify-between items-center mb-2">
							<span className="font-bold text-gray-700">{line.role}</span>
							{index === currentLineIndex && line.role === userRole && (
								<button
									onClick={() => setIsRecording(prev => !prev)}
									className={`${isRecording ? 'bg-red-500' : 'bg-blue-500'
										} hover:opacity-80 text-white px-4 py-2 rounded-full flex items-center gap-2`}
								>
									<FaMicrophone />
									{isRecording ? 'Recording...' : 'Record'}
								</button>
							)}
							{index === currentLineIndex && line.role !== userRole && (
								<button
									onClick={playCurrentLine}
									disabled={isPlaying}
									className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-full flex items-center gap-2"
								>
									<FaVolumeLow />
									{isPlaying ? 'Playing...' : 'Play'}
								</button>
							)}
							{index < currentLineIndex && (
								<FaCheck className="text-green-500" />
							)}
						</div>
						<p className="text-gray-600">{line.line}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default RoleplayConversation;