// storage.ts
import { Preferences } from "@capacitor/preferences";
import { useState, useEffect, useCallback } from "react";

// Helper function to check if running in browser
const isBrowser = typeof window !== "undefined";

// Basic storage interface
interface StorageInterface {
  get(key: string): Promise<string | null>;
  set(key: string, value: string): Promise<void>;
  remove(key: string): Promise<void>;
}

// Capacitor Storage Implementation
const capacitorStorage: StorageInterface = {
  async get(key: string) {
    const { value } = await Preferences.get({ key });
    return value;
  },
  async set(key: string, value: string) {
    await Preferences.set({ key, value });
  },
  async remove(key: string) {
    await Preferences.remove({ key });
  },
};

// Browser localStorage Implementation
const browserStorage: StorageInterface = {
  async get(key: string) {
    return localStorage.getItem(key);
  },
  async set(key: string, value: string) {
    localStorage.setItem(key, value);
  },
  async remove(key: string) {
    localStorage.removeItem(key);
  },
};

// Use appropriate storage based on environment
const storage = isBrowser ? browserStorage : capacitorStorage;

// Storage utility functions
export async function checkStorage(key: string): Promise<boolean> {
  const item = await storage.get(key);
  return !!item;
}

export async function saveToStorage<T>(key: string, value: T): Promise<void> {
  await storage.set(key, JSON.stringify(value));
}

export async function loadFromStorage<T>(
  key: string,
  defaultValue: T
): Promise<T> {
  const item = await storage.get(key);
  return item ? JSON.parse(item) : defaultValue;
}

export async function deleteFromStorage(key: string): Promise<void> {
  await storage.remove(key);
}

// Blob storage utilities using IndexedDB
const DB_NAME = "BlobStorage";
const STORE_NAME = "blobs";

async function getIndexedDB(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      db.createObjectStore(STORE_NAME);
    };
  });
}

export async function saveBlobToStorage(
  key: string,
  blob: Blob
): Promise<void> {
  // Convert Blob to ArrayBuffer for more reliable storage
  const arrayBuffer = await blob.arrayBuffer();

  const db = await getIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, "readwrite");
    const store = transaction.objectStore(STORE_NAME);

    // Store the blob type along with the array buffer
    const data = {
      buffer: arrayBuffer,
      type: blob.type,
    };

    const request = store.put(data, key);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve();

    // Close the database connection when done
    transaction.oncomplete = () => db.close();
  });
}

export async function loadBlobFromStorage(key: string): Promise<Blob | null> {
  const db = await getIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, "readonly");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(key);

    request.onerror = () => reject(request.error);
    request.onsuccess = () => {
      if (!request.result) {
        resolve(null);
        return;
      }

      // Reconstruct the Blob from the stored data
      const blob = new Blob([request.result.buffer], {
        type: request.result.type,
      });
      resolve(blob);
    };

    // Close the database connection when done
    transaction.oncomplete = () => db.close();
  });
}

type UseLocalStorageReturn<T> = [T, React.Dispatch<React.SetStateAction<T>>];

// React Hook for storage
export function useLocalStorage<T>(
  key: string,
  initialValue: T
): UseLocalStorageReturn<T> {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.error(error);
    }
  }, [key, storedValue]);

  const setValue: React.Dispatch<React.SetStateAction<T>> = (value) => {
    try {
      console.log("Setting value", value);
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

export function setLocalStorage<T>(key: string, value: T): void {
  localStorage.setItem(key, JSON.stringify(value));
}
