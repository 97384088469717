import _ from "lodash";
import { Scenario, Message } from "../types";
declare global {
  interface Window {
    difflib: any;
  }
}

export function classNames(...classes: string[]): string {
  return classes
    .filter(Boolean)
    .map((className) =>
      className.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
    )
    .join(" ");
}

export function calculateOverallPronunciationScore(
  referenceText: string,
  allWords: any,
  currentText: [],
  startOffset: number,
  recognizedWords: any,
  fluencyScores: any,
  prosodyScores: any,
  durations: any,
  jo: any
) {
  const language = "en-US";
  const scoreNumber = {
    accuracyScore: 0,
    fluencyScore: 0,
    compScore: 0,
    prosodyScore: 0,
  } as any;

  const resText = currentText.join(" ");
  let wholelyricsArry = [];
  let resTextArray = [];

  let resTextProcessed = (resText.toLocaleLowerCase() ?? "")
    .replace(new RegExp('[!"#$%&()*+,-./:;<=>?@[^_`{|}~]+', "g"), "")
    .replace(new RegExp("]+", "g"), "");
  let wholelyrics = (referenceText.toLocaleLowerCase() ?? "")
    .replace(new RegExp('[!"#$%&()*+,-./:;<=>?@[^_`{|}~]+', "g"), "")
    .replace(new RegExp("]+", "g"), "");
  wholelyricsArry = wholelyrics.split(" ");
  resTextArray = resTextProcessed.split(" ");

  const wholelyricsArryRes = _.map(
    _.filter(wholelyricsArry, (item: any) => !!item),
    (item: any) => item.trim()
  );

  // For continuous pronunciation assessment mode, the service won't return the words with `Insertion` or `Omission`
  // We need to compare with the reference text after received all recognized words to get these error words.
  const diff = new window.difflib.SequenceMatcher(
    null,
    wholelyricsArryRes,
    resTextArray
  );
  const lastWords: any[] = [];
  for (const d of diff.getOpcodes()) {
    if (d[0] == "insert" || d[0] == "replace") {
      if (["zh-cn"].includes(language.toLowerCase())) {
        for (let j = d[3], count = 0; j < d[4]; count++) {
          let len = 0;
          let bfind = false;
          _.map(allWords, (item: any, index: number) => {
            if (
              (len == j ||
                (index + 1 < allWords.length &&
                  allWords[index].Word.length > 1 &&
                  j > len &&
                  j < len + allWords[index + 1].Word.length)) &&
              !bfind
            ) {
              const wordNew = _.cloneDeep(allWords[index]);
              if (
                allWords &&
                allWords.length > 0 &&
                allWords[index].PronunciationAssessment.ErrorType !==
                  "Insertion"
              ) {
                wordNew.PronunciationAssessment.ErrorType = "Insertion";
              }
              lastWords.push(wordNew);
              bfind = true;
              j += allWords[index].Word.length;
            }
            len = len + item.Word.length;
          });
        }
      } else {
        for (let j = d[3]; j < d[4]; j++) {
          if (
            allWords &&
            allWords.length > 0 &&
            allWords[j].PronunciationAssessment.ErrorType !== "Insertion"
          ) {
            allWords[j].PronunciationAssessment.ErrorType = "Insertion";
          }
          lastWords.push(allWords[j]);
        }
      }
    }
    if (d[0] == "delete" || d[0] == "replace") {
      if (
        d[2] == wholelyricsArryRes.length &&
        !(jo.RecognitionStatus == "Success" || jo.RecognitionStatus == "Failed")
      )
        continue;
      for (let i = d[1]; i < d[2]; i++) {
        const word = {
          Word: wholelyricsArryRes[i],
          PronunciationAssessment: {
            ErrorType: "Omission",
          },
        };
        lastWords.push(word);
      }
    }
    if (d[0] == "equal") {
      for (let k = d[3], count = 0; k < d[4]; count++) {
        if (["zh-cn"].includes(language.toLowerCase())) {
          let len = 0;
          let bfind = false;
          _.map(allWords, (item: any, index: number) => {
            if (len >= k && !bfind) {
              if (
                allWords[index].PronunciationAssessment.ErrorType !== "None"
              ) {
                allWords[index].PronunciationAssessment.ErrorType = "None";
              }
              lastWords.push(allWords[index]);
              bfind = true;
              k += allWords[index].Word.length;
            }
            len = len + item.Word.length;
          });
        } else {
          lastWords.push(allWords[k]);
          k++;
        }
      }
    }
  }

  let reference_words = [];
  if (["zh-cn"].includes(language.toLowerCase())) {
    reference_words = allWords;
  } else {
    reference_words = wholelyricsArryRes;
  }

  let recognizedWordsRes = [];
  _.forEach(recognizedWords, (word: any) => {
    if (word.PronunciationAssessment.ErrorType == "None") {
      recognizedWordsRes.push(word);
    }
  });

  let compScore = Number(
    ((recognizedWordsRes.length / reference_words.length) * 100).toFixed(0)
  );
  if (compScore > 100) {
    compScore = 100;
  }
  scoreNumber.compScore = compScore;

  const accuracyScores = [] as any;
  _.forEach(lastWords, (word: any) => {
    if (word && word?.PronunciationAssessment?.ErrorType != "Insertion") {
      accuracyScores.push(
        Number(word?.PronunciationAssessment.AccuracyScore ?? 0)
      );
    }
  });
  scoreNumber.accuracyScore = Number(
    (_.sum(accuracyScores) / accuracyScores.length).toFixed(0)
  );

  if (startOffset) {
    const sumRes = [] as any;
    _.forEach(fluencyScores, (x: any, index: number) => {
      sumRes.push(x * durations[index]);
    });
    scoreNumber.fluencyScore = _.sum(sumRes) / _.sum(durations);
  }
  scoreNumber.prosodyScore = Number(
    (_.sum(prosodyScores) / prosodyScores.length).toFixed(0)
  );

  const sortScore = Object.keys(scoreNumber).sort(function (a, b) {
    return scoreNumber[a] - scoreNumber[b];
  });
  if (jo.RecognitionStatus == "Success" || jo.RecognitionStatus == "Failed") {
    scoreNumber.pronScore = Number(
      (
        scoreNumber[sortScore["0"]] * 0.4 +
        scoreNumber[sortScore["1"]] * 0.2 +
        scoreNumber[sortScore["2"]] * 0.2 +
        scoreNumber[sortScore["3"]] * 0.2
      ).toFixed(0)
    );
  } else {
    scoreNumber.pronScore = Number(
      (
        scoreNumber.accuracyScore * 0.5 +
        scoreNumber.fluencyScore * 0.5
      ).toFixed(0)
    );
  }

  //console.log("    Paragraph pronunciation score: ", scoreNumber.pronScore, ", accuracy score: ", scoreNumber.accuracyScore, ", completeness score: ", scoreNumber.compScore, ", fluency score: ", scoreNumber.fluencyScore, ", prosody score: ", scoreNumber.prosodyScore);

  //_.forEach(lastWords, (word, ind) => {
  //console.log(word);
  //console.log("    ", ind + 1, ": word: ", word.Word, "\taccuracy score: ", word.PronunciationAssessment.AccuracyScore, "\terror type: ", word.PronunciationAssessment.ErrorType, ";");
  //});

  return [lastWords, scoreNumber];
}

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getLatestScenarioInfo(messages: Message[]) {
  let latestTask: string | undefined;
  let latestName: string | undefined;
  let latestDescription: string | undefined;
  let latestAdditionalContext: any = {};

  for (let i = messages.length - 1; i >= 0; i--) {
    const msg = messages[i];

    if (!latestTask && msg.task) latestTask = msg.task;
    if (!latestName && msg.scenarioName) latestName = msg.scenarioName;
    if (!latestDescription && msg.description)
      latestDescription = msg.description;
    if (
      Object.keys(latestAdditionalContext).length === 0 &&
      msg.additional_context
    )
      latestAdditionalContext = msg.additional_context;

    // Break early if all fields are found
    if (
      latestTask &&
      latestName &&
      latestDescription &&
      Object.keys(latestAdditionalContext).length > 0
    ) {
      break;
    }
  }

  const task = latestTask || "";
  const name = latestName || "";
  const description = latestDescription || "";
  const additionalContext =
    latestAdditionalContext && typeof latestAdditionalContext === "object"
      ? Object.values(latestAdditionalContext).join(" ").trim()
      : "";

  return { task, name, description, additionalContext };
}

export function isObjectEmpty(obj: any) {
  if (!obj) return true;

  return Object.keys(obj).length === 0;
}

export const languageFlags: { [key: string]: string } = {
  English: "🇬🇧",
  Spanish: "🇪🇸",
  French: "🇫🇷",
  German: "🇩🇪",
  Italian: "🇮🇹",
  Chinese: "🇨🇳",
  Japanese: "🇯🇵",
  Korean: "🇰🇷",
  Russian: "🇷🇺",
  Portuguese: "🇵🇹",
  Arabic: "🇸🇦",
  Hindi: "🇮🇳",
  Bengali: "🇧🇩",
  Turkish: "🇹🇷",
  Vietnamese: "🇻🇳",
  Thai: "🇹🇭",
  Dutch: "🇳🇱",
  Greek: "🇬🇷",
  Polish: "🇵🇱",
  Swedish: "🇸🇪",
  Czech: "🇨🇿",
  Romanian: "🇷🇴",
  Hungarian: "🇭🇺",
  Finnish: "🇫🇮",
  Danish: "🇩🇰",
  Norwegian: "🇳🇴",
  Hebrew: "🇮🇱",
  Indonesian: "🇮🇩",
  Malaysian: "🇲🇾",
  Filipino: "🇵🇭",
  Ukrainian: "🇺🇦",
  Persian: "🇮🇷",
  Serbian: "🇷🇸",
  Croatian: "🇭🇷",
  Bulgarian: "🇧🇬",
  Slovak: "🇸🇰",
  Slovenian: "🇸🇮",
  Lithuanian: "🇱🇹",
  Latvian: "🇱🇻",
  Estonian: "🇪🇪",
  Icelandic: "🇮🇸",
  Irish: "🇮🇪",
  Welsh: "🇬🇧",
  Swahili: "🇹🇿",
  Urdu: "🇵🇰",
  Tamil: "🇮🇳",
  Telugu: "🇮🇳",
  Kannada: "🇮🇳",
  Malayalam: "🇮🇳",
  Marathi: "🇮🇳",
  Punjabi: "🇮🇳",
  Gujarati: "🇮🇳",
  Nepali: "🇳🇵",
  Mongolian: "🇲🇳",
  Khmer: "🇰🇭",
  Lao: "🇱🇦",
  Burmese: "🇲🇲",
  Azerbaijani: "🇦🇿",
  Georgian: "🇬🇪",
  Armenian: "🇦🇲",
  Albanian: "🇦🇱",
  Macedonian: "🇲🇰",
  Kazakh: "🇰🇿",
  Uzbek: "🇺🇿",
  Tajik: "🇹🇯",
  Kyrgyz: "🇰🇬",
  Turkmen: "🇹🇲",
  Maltese: "🇲🇹",
  Luxembourgish: "🇱🇺",
  Catalan: "🇦🇩",
  Basque: "🇪🇸",
  Galician: "🇪🇸",
  Frisian: "🇳🇱",
  Maori: "🇳🇿",
  Samoan: "🇼🇸",
  Hawaiian: "🇺🇸",
};

export const languages = [
  "Albanian",
  "Arabic",
  "Armenian",
  "Azerbaijani",
  "Basque",
  "Bengali",
  "Bulgarian",
  "Burmese",
  "Catalan",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Estonian",
  "Filipino",
  "Finnish",
  "French",
  "Frisian",
  "Galician",
  "Georgian",
  "German",
  "Greek",
  "Gujarati",
  "Hawaiian",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Irish",
  "Italian",
  "Japanese",
  "Kannada",
  "Kazakh",
  "Khmer",
  "Korean",
  "Kyrgyz",
  "Lao",
  "Latvian",
  "Lithuanian",
  "Luxembourgish",
  "Macedonian",
  "Malayalam",
  "Malaysian",
  "Maltese",
  "Maori",
  "Marathi",
  "Mongolian",
  "Nepali",
  "Norwegian",
  "Persian",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Romanian",
  "Russian",
  "Samoan",
  "Serbian",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tamil",
  "Telugu",
  "Thai",
  "Turkish",
  "Turkmen",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Welsh",
];

export function reduceByRole(messages: Message[]): Message[] {
  return messages.reduce((acc: Message[], current: Message) => {
    if (acc.length === 0) {
      // First element
      return [current];
    }

    const lastMessage = acc[acc.length - 1];
    if (lastMessage.role === current.role) {
      // Same role as previous, concatenate content
      lastMessage.content += " " + current.content;
      return acc;
    } else {
      // Different role, add as new element
      return [...acc, current];
    }
  }, []);
}

export function hashString(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash; // Convert to 32-bit integer
  }
  return hash;
}

export function identifyPronunciationProblems(
  wordsData: any,
  options = {
    phonemeThreshold: 60,
    syllableThreshold: 60,
    wordThreshold: 65,
  }
) {
  const problems = [] as any;

  wordsData.forEach((word: any) => {
    const wordProblems = {
      word: word.Word,
      problems: [],
    } as any;

    // Word-level check
    if (
      word.PronunciationAssessment.ErrorType !== "None" ||
      word.PronunciationAssessment.AccuracyScore < options.wordThreshold
    ) {
      wordProblems.problems.push({
        type: "word_error",
        errorType: word.PronunciationAssessment.ErrorType,
        score: word.PronunciationAssessment.AccuracyScore,
        severity: getSeverityLevel(word.PronunciationAssessment.AccuracyScore),
      } as any);
    }

    // Syllable-level check
    word.Syllables.forEach((syllable: any) => {
      if (
        syllable.PronunciationAssessment.AccuracyScore <
        options.syllableThreshold
      ) {
        wordProblems.problems.push({
          type: "syllable_error",
          syllable: syllable.Syllable,
          score: syllable.PronunciationAssessment.AccuracyScore,
          severity: getSeverityLevel(
            syllable.PronunciationAssessment.AccuracyScore
          ),
        });
      }
    });

    // Phoneme-level check
    word.Phonemes.forEach((phoneme: any) => {
      if (
        phoneme.PronunciationAssessment.AccuracyScore < options.phonemeThreshold
      ) {
        wordProblems.problems.push({
          type: "phoneme_error",
          phoneme: phoneme.Phoneme,
          score: phoneme.PronunciationAssessment.AccuracyScore,
          timing: {
            offset: phoneme.Offset,
            duration: phoneme.Duration,
          },
          severity: getSeverityLevel(
            phoneme.PronunciationAssessment.AccuracyScore
          ),
        });
      }
    });

    if (wordProblems.problems.length > 0) {
      problems.push(wordProblems);
    }
  });

  return problems;
}

function getSeverityLevel(score: number) {
  if (score < 60) return "critical";
  if (score < 75) return "high";
  if (score < 90) return "medium";
  return "low";
}
