import { useState, useEffect } from 'react';
import confetti from 'canvas-confetti';
import { hashString } from '../../../../utils/Helpers';
interface MatchItem {
	word: string;
	image: {
		url: string;
		alt: string;
	};
}

interface WordPictureMatchProps {
	items: MatchItem[];
	results: any;
	setResults: (results: any) => void;
}

const highlightWord = (text: string, wordToHighlight: string) => {
	if (!wordToHighlight) return text;

	const parts = text.split(new RegExp(`(${wordToHighlight})`, 'gi'));

	return parts.map((part, index) =>
		part.toLowerCase() === wordToHighlight.toLowerCase() ? (
			<span key={index} className="bg-yellow-200 px-1 rounded">
				{part}
			</span>
		) : (
			part
		)
	);
};

const WordPictureMatch: React.FC<WordPictureMatchProps> = ({ items, results, setResults }) => {
	const [selectedWord, setSelectedWord] = useState<string | null>(null);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);

	useEffect(() => {
		if (selectedWord && selectedImage) {
			const newMatches = { ...results };

			// Remove any existing matches for this image
			Object.keys(newMatches).forEach(key => {
				if (newMatches[key] === selectedImage) {
					delete newMatches[key];
				}
			});

			// Remove any existing match for this word
			if (selectedWord in newMatches) {
				delete newMatches[selectedWord];
			}

			// Add the new match
			newMatches[selectedWord] = selectedImage;

			setResults(newMatches);

			// Reset selections
			setTimeout(() => {
				setSelectedWord(null);
				setSelectedImage(null);
			}, 500);
		}
	}, [selectedWord, selectedImage]);

	const isWordMatched = (word: string) => {
		if (!results) return false;

		return Object.keys(results).includes(word);
	};

	const isImageMatched = (imageAlt: string) => {
		if (!results) return false;
		return Object.values(results).some((item: any) => item === imageAlt);
	};

	const sortedItems = items.slice().sort((a, b) => hashString(a.image.alt) - hashString(b.image.alt));


	return (
		<div className="max-w-7xl mx-auto p-4">
			<h1 className="text-3xl font-bold text-center mb-12 text-gray-800">
				Match the Words to Pictures
			</h1>

			<div className="container mx-auto">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-12 justify-center">
					{/* Words Column */}
					<div>
						<div className="bg-white p-8 rounded-2xl shadow-lg">
							<h2 className="text-2xl font-semibold mb-8 text-gray-700">Words</h2>
							<div className="grid gap-6">
								{items.map((item, index) => (
									<div
										key={index}
										className={`
											p-6 rounded-xl transition-all duration-300 bg-gray-50 hover:bg-blue-50 hover:shadow-md cursor-pointer
											${isWordMatched(item.word)
												? 'opacity-30'
												: ''
											}
											${selectedWord === item.word ? 'ring-2 ring-blue-500' : ''}
										`}
										onClick={() => setSelectedWord(item.word)}
									>
										<span className="text-xl font-medium text-gray-700">
											{item.word}
										</span>
									</div>
								))}
							</div>
						</div>
					</div>

					{/* Images Column */}
					<div className="container mx-auto px-4">
						<div className="bg-white p-4 sm:p-8 rounded-2xl shadow-lg">
							<h2 className="text-2xl font-semibold mb-4 sm:mb-8 text-gray-700">Pictures</h2>
							<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 sm:gap-6">
								{sortedItems.map((item, index) => (
									<div
										key={index}
										className={`
            relative aspect-w-16 aspect-h-9 rounded-xl overflow-hidden
            transition-all duration-300 cursor-pointer hover:opacity-90 hover:shadow-lg hover:transform hover:scale-105
            ${isImageMatched(item.image.alt)
												? 'opacity-30'
												: ''
											}
            ${selectedImage === item.image.alt ? 'ring-4 ring-blue-500' : ''}
          `}
										onClick={() => setSelectedImage(item.image.alt)}
									>
										<img
											src={item.image.url}
											alt={item.image.alt}
											className="w-full h-full object-cover bg-gray-50"
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WordPictureMatch;