import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { IoChatbox, IoChatbubbles, IoSend, IoHelpCircleOutline, IoSettingsOutline } from 'react-icons/io5';

import styles from "./Chat.module.css";
import {
	Message, ChatLocationState, RoleplaySettingsProps,
	defaultRoleplaySettings,
	RoleplayScores,
	Scenario,
} from "../../types";

import { useLocation } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import scenarios from "../../data/Scenarios";

import { useServer } from "../../hooks/useServer";
import { useLoading } from "../../hooks/LoadingProvider";

import RoleplaySettings from "../../components/roleplay/RoleplaySettings";

import { useLocalStorage } from "../../hooks/useLocalStorage";

import { getLatestScenarioInfo } from "../../utils/Helpers";



import ChatHeader from "../../components/roleplay/ChatHeader";
import MessageList from "../../components/roleplay/MessageList";
import ScoresList from "../../components/roleplay/ScoresList";
import ChatInput from "../../components/roleplay/ChatInput";

import CLBScoresList from "../../components/roleplay/CLBScoresList";


import { useAzure } from "../../hooks/useAzure";
import { useToast } from "../../hooks/ToastProvider";

const Chat: React.FC = () => {

	const navigate = useNavigate();
	const { chat, getFeedbackAndScores, generateNewTask } = useServer();
	const { showLoading, hideLoading, isLoading } = useLoading();
	const { stopTextAsync } = useAzure();
	const { showToast } = useToast();

	const [scenario, setCurrentRoleplayScenario] =
		useLocalStorage<Scenario | null>("currentRoleplayScenario", null);
	const [taskCount, setTaskCount] = useState(0);

	const [CLBScores, setCLBScores] = useState(null);
	const [scores, setScores] = useState<RoleplayScores | null>(null);



	//const [messages, setMessages] = useState<Message[]>(scenarioMessages);
	const [unblurredSet, setUnblurredSet] = useState<Set<number>>(new Set([0]));

	const [input, setInput] = useState<string>("");

	const [showSettings, setShowSettings] = useState(false);
	const [settings, setSettings] = useLocalStorage<RoleplaySettingsProps>(
		"RoleplaySettings",
		defaultRoleplaySettings
	);

	const [isPlayingIndex, setIsPlayingIndex] = useState(-1);

	useEffect(() => {

		return () => {
			setIsPlayingIndex(-1);
			stopTextAsync();

			// if there is current scores
			if (scores) {
				setCurrentRoleplayScenario(null);
			}
		}
	}, []);

	useEffect(() => {
		if (!scenario) {
			showLoading("Loading...");
		} else {
			hideLoading();
		}

		// set settings based on input mdoe
		if (scenario?.inputMode) {
			setSettings({
				...settings,
				inputType: scenario.inputMode === "Speaking" ? "voice" : "text",
				outputType: scenario.outputMode === "Listening" ? "voice" : "text",
			});
		}
	}, [scenario]);

	const handleBack = () => {
		navigate(-1);
	};

	const sendMessage = async (input: string) => {
		if (input.trim()) {
			const newMessage: Message = { role: "user", content: input.trim() };
			const newMessages = [...scenario!.messages, newMessage];

			setCurrentRoleplayScenario({ ...scenario!, messages: newMessages });


			setInput("");

			try {
				// convert all image messages to system messages with description as content
				const textMessages = newMessages.map<Message>((message) => {
					if (message.role === "image") {
						return {
							role: "system",
							content: message.content,
						};
					}
					return message;
				});

				let response = await chat(textMessages);

				// check if "--End conversation--" is in
				const endIdx = response.search(/--end conversation--/i);

				if (endIdx !== -1) {
					console.log("End conversation");
					response = response.slice(0, endIdx);
				}

				const responseMessage: Message = {
					role: "assistant",
					content: response,
				};

				setCurrentRoleplayScenario({
					...scenario!,
					messages: [...newMessages, responseMessage],
				});

				if (endIdx !== -1) {
					endSession();
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	const endSession = async () => {
		try {
			console.log("Task count: ", taskCount);
			if (taskCount < 0) {
				showLoading("Generating New Task",);
				// get new task
				const newScenario = await generateNewTask(scenario!);
				console.log("New Scenario: ", newScenario);
				setCurrentRoleplayScenario(newScenario);

				setTaskCount(taskCount + 1);
				return;
			}

			showLoading("Getting Feedback");

			const [feedback, scores, CLBScores] = await getFeedbackAndScores(scenario!);

			// iterate the messages
			const messages = scenario!.messages;
			let feedbackIdx = 0;
			for (let i = 0; i < messages.length; i++) {
				if (messages[i].role === "user") {
					messages[i].feedback = feedback[feedbackIdx++];
				}
			}

			setScores(scores);
			setCLBScores(CLBScores);
			//setCurrentRoleplayScenario(null);
		} catch (error) {
			console.error(error);

			showToast("Error getting feedback and scores");
		} finally {
			hideLoading();
		}
	};


	const hasFeedback = scores !== null;




	return (
		<div className="min-h-screen bg-gray-100 flex flex-col">
			{scenario && (
				<Fragment>
					<ChatHeader
						scenario={scenario!}
						handleBack={handleBack}
						setShowSettings={setShowSettings}
						loading={isLoading}
					/>

					<div className="flex-1 flex flex-col">
						<main className={`flex-1 overflow-y-auto ${isLoading ? "blur-sm" : ""}`}>
							<div className="max-w-3xl mx-auto h-full border-x-2 border-gray-300">
								<div className="p-4">
									<MessageList
										hasFeedback={hasFeedback}
										scenario={scenario!}
										settings={settings}
										unblurredSet={unblurredSet}
										setUnblurredSet={setUnblurredSet}
										isPlayingIndex={isPlayingIndex}
										setIsPlayingIndex={setIsPlayingIndex}
									/>

									{!scores && scenario!.messages.length > 5 && (
										<div className="flex justify-center mt-10">
											<button
												className="bg-blue-500 text-white rounded-full px-4 py-2 text-xs hover:bg-blue-600 transition-colors"
												onClick={endSession}
											>
												End Conversation
											</button>
										</div>
									)}

									<CLBScoresList scores={CLBScores} />
									<ScoresList scores={scores} />
								</div>
							</div>
						</main>

						<footer className={`bg-transparent w-full ${isLoading ? "blur-sm" : ""}`}>
							<div className="max-w-3xl mx-auto ">
								<ChatInput
									input={input}
									setInput={setInput}
									sendMessage={sendMessage}
									inputType={settings.inputType}
								/>
							</div>
						</footer>
					</div>

					<RoleplaySettings
						showSettings={showSettings}
						setShowSettings={setShowSettings}
						settings={settings}
						setSettings={setSettings}
					/>
				</Fragment>
			)}
		</div>
	);
};

export default Chat;
