import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { useAuth } from "../hooks/useAuth";
import { useServer } from "../hooks/useServer";
import { useLoading } from "../hooks/LoadingProvider";
import { useToast } from "../hooks/ToastProvider";

const Login = () => {
	const {
		loginWithEmailAndPassword,
		registerWithEmailAndPassword,
		loginWithFacebook,
		loginWithGoogle,
	} = useAuth();
	const { showLoading, hideLoading } = useLoading();
	const { showToast } = useToast();
	const { setUser } = useServer();

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isLogin, setIsLogin] = useState(true);

	const navigate = useNavigate();

	const handleGoogleLogin = async () => {
		console.log("Logging in with Google");
		showLoading("Logging in with Google");

		try {
			if (await loginWithGoogle()) {
				console.log("Logged in");
				navigate("/profile");
			}
		} catch (error: any) {
			console.error(error);
			showToast(error.message);
		} finally {
			hideLoading();
		}
	};

	const handleFacebookLogin = async () => {
		console.log("Logging in with Facebook");
		showLoading("Logging in with Facebook");
		try {
			if (await loginWithFacebook()) {
				console.log("Logged in");
				navigate("/profile");
			}
		} catch (error: any) {
			console.error(error);
			showToast(error.message);
		} finally {
			hideLoading();
		}
	};

	const handleLogin = async () => {
		console.log("Logging in with:", { email });
		showLoading("Logging in");
		try {
			if (await loginWithEmailAndPassword({ email, password })) {
				console.log("Logged in");
				navigate("/profile");
			}
		} catch (error: any) {
			console.error(error);
			showToast(error);
		} finally {
			hideLoading();
		}
	};

	const handleRegister = async () => {
		console.log("Registering with:", { email, password });
		showLoading("Registering");
		try {
			const user = await registerWithEmailAndPassword({ name, email, password });
			console.log(user);

			if (user) {
				console.log("Registered");
				setUser(user);
				navigate("/profile");
			}
		} catch (error: any) {
			console.error(error);
			showToast(error);
		} finally {
			hideLoading();
		}
	};

	return (
		<div className="min-h-screen bg-white">
			<div className="w-72 mx-auto pt-8">
				<img
					src="logo.png"
					alt="Logo"
					className="w-16 my-8 mx-auto md:w-24 lg:w-48 mb-5"
				/>

				<div>
					<div className="mb-4 text-center">
						<h1 className="text-2xl font-bold">
							{isLogin ? "Welcome back" : "Create an account"}
						</h1>
					</div>

					{!isLogin && (
						<div className="mb-4">
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="Username"
								className="w-full p-3 border rounded-lg focus:outline-none focus:border-blue-500"
							/>
						</div>
					)}

					<div className="mb-4">
						<input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="Email"
							className="w-full p-3 border rounded-lg focus:outline-none focus:border-blue-500"
							autoSave="on"
						/>
					</div>

					<div className="mb-4">
						<input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="Password"
							className="w-full p-3 border rounded-lg focus:outline-none focus:border-blue-500"
						/>
					</div>

					<button
						className="w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
						onClick={isLogin ? handleLogin : handleRegister}
					>
						Continue
					</button>

					<p className="text-sm mt-4 text-center">
						{isLogin ? (
							<>
								Don't have an account?{" "}
								<button
									onClick={() => setIsLogin(false)}
									className="text-blue-500 hover:underline"
								>
									Sign up
								</button>
							</>
						) : (
							<>
								Already have an account?{" "}
								<button
									onClick={() => setIsLogin(true)}
									className="text-blue-500 hover:underline"
								>
									Log in
								</button>
							</>
						)}
					</p>

					<div className="flex items-center justify-center my-6">
						<div className="flex-1 border-b border-gray-300"></div>
						<span className="px-4 text-sm text-gray-500">OR</span>
						<div className="flex-1 border-b border-gray-300"></div>
					</div>

					<div className="space-y-3">
						<button
							onClick={handleGoogleLogin}
							className="w-full p-3 border rounded-lg flex items-center justify-center space-x-2 hover:bg-gray-50 transition-colors"
						>
							<FcGoogle className="text-xl" />
							<span>Continue with Google</span>
						</button>

						<button
							onClick={handleFacebookLogin}
							className="w-full p-3 border rounded-lg flex items-center justify-center space-x-2 hover:bg-gray-50 transition-colors"
						>
							<FaFacebook className="text-xl text-blue-600" />
							<span>Continue with Facebook</span>
						</button>
					</div>
				</div>

				<div className="fixed inset-x-0 bottom-0 text-center text-sm py-4 bg-white">
					<a href="#" className="text-blue-500 mx-1 hover:underline">
						Terms of Use
					</a>
					|
					<a href="#" className="text-blue-500 mx-1 hover:underline">
						Privacy Policy
					</a>
				</div>
			</div>
		</div>
	);
};

export default Login;