import * as sdk from "microsoft-cognitiveservices-speech-sdk";

export interface Selection {
  start: number | null;
  end: number | null;
}

export interface Image {
  src: string;
  caption: string;
  filepath: string;
}

export interface ChatLocationState {
  selectedScenarioId: number;
}

export interface PronunciationResult {
  audio: string;
  words: any[];
  content: string;
  scores: any;
  sessionId: string;
  timestamp: string;
  userId: string;
  interest: string;
  role: string;
  metric: string;
  lessons: any[];
}

export interface PronunciationProgress {
  name: string;
  sessionId: string;
  speechElements: any;
  isAssessment: boolean;
  isFrequency: boolean;
  role: string;
  interest: string;
  metrics?: string[];

  // For progress tracking
  results?: any[];
  current?: number;
  remainingSeconds?: number; // if assessment
}

export interface PronunciationSettingsProps {
  autoStart: boolean;
  showPhoneticRepresentation: boolean;
  showDefinition: boolean;
  showExample: boolean;
  showImage: boolean;
}

export const defaultPronunciationSettings: PronunciationSettingsProps = {
  autoStart: false,
  showPhoneticRepresentation: false,
  showDefinition: true,
  showExample: true,
  showImage: false,
};

export interface RoleplaySettingsProps {
  showSuggestions: boolean;
  speak: boolean;
  speakingSpeed: number;
  liveConversation: boolean;
  inputType: "text" | "voice";
  outputType: "text" | "voice";
}

export const defaultRoleplaySettings: RoleplaySettingsProps = {
  showSuggestions: true,
  speak: true,
  speakingSpeed: 1,
  liveConversation: true,
  inputType: "voice",
  outputType: "voice",
};

export interface Message {
  role: "user" | "assistant" | "system" | "image";
  content: string;
  image?: Image;
  scenarioName?: string;
  task?: string;
  description?: string;
  roleplay_instructions?: string;
  additional_context?: any;

  feedback?: string;
}

export interface Scenario {
  id: number;

  imgSrc: string;
  messages: Message[];

  sampleVoiceResponse: string; // path to audio file
  voiceId: string;

  interest: string;
  role: string;

  // For tracking
  inputMode?: "Speaking" | "Writing";
  outputMode?: "Listening" | "Reading";
  inputScore?: number;
  outputScore?: number;
}

export interface RoleplayScores {
  grammarScore: {
    score: number;
    justification: string;
  };
  wordChoice: {
    score: number;
    justification: string;
  };
  precision: {
    score: number;
    justification: string;
  };
  listeningComprehension: {
    score: number;
    justification: string;
  };
  useOfConnectors: {
    score: number;
    justification: string;
  };
  questionUse: {
    score: number;
    justification: string;
  };
}

export interface Word {
  Word: string;
  Offset: number;
  Duration: number;
  PronunciationAssessment: {
    AccuracyScore: number;
    ErrorType: string;
    Feedback: {
      Prosody: {
        Break: {
          ErrorTypes: string[];
          BreakLength: number;
          UnexpectedBreak?: {
            Confidence: number;
          };
          MissingBreak?: {
            Confidence: number;
          };
        };
        Intonation: {
          ErrorTypes: string[];
          Monotone: {
            SyllablePitchDeltaConfidence: number;
          };
        };
      };
    };
  };
  Syllables: Array<{
    Syllable: string;
    Grapheme: string;
    PronunciationAssessment: {
      AccuracyScore: number;
    };
    Offset: number;
    Duration: number;
  }>;
  Phonemes: Array<{
    Phoneme: string;
    PronunciationAssessment: {
      AccuracyScore: number;
    };
    Offset: number;
    Duration: number;
  }>;
}

export interface RecognitionCallbacks {
  recognizing: (
    sender: sdk.Recognizer,
    event: sdk.SpeechRecognitionEventArgs
  ) => void;
  recognized: (
    sender: sdk.Recognizer,
    event: sdk.SpeechRecognitionEventArgs
  ) => void;
  canceled?: (event: sdk.SpeechRecognitionCanceledEventArgs) => void;
  sessionStopped?: (
    sender: sdk.Recognizer,
    event: sdk.SessionEventArgs
  ) => void;
  results: (words: any, scores: any, blob: Blob) => void;
}

export type Exercise = {
  content: any;
  exerciseType: string;
  rationale: string;
};

export interface LiveTutorSettings {
  volume: number;
  liveConversation: boolean;
  autoPlayMessages: boolean;
  speakingRate: number;
}
