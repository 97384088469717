import React, { createContext, useContext, useState, ReactNode } from 'react';



// Create the context with an initial default value
const BlendShapeContext = createContext<BlendShapeContextType | undefined>(undefined);

// Props interface for the provider component
interface BlendShapeProviderProps {
	children: ReactNode;
}
// types.ts
interface BlendShapeFrame {
	frameIndex: number;
	blendShapes: number[][];
	timestamp: number;
}

// BlendShapeProvider.tsx
interface BlendShapeContextType {

	blendShapeQueue: BlendShapeFrame[];
	addToBlendShapeQueue: (frame: BlendShapeFrame) => void;
	clearBlendShapeQueue: () => void;
	popBlendShapeQueue: () => BlendShapeFrame | undefined;
	addEndBlendShapeFrame: () => void;
}

export const BlendShapeProvider: React.FC<BlendShapeProviderProps> = ({ children }) => {
	const [blendShapeQueue, setBlendShapeQueue] = useState<BlendShapeFrame[]>([]);

	const addEndBlendShapeFrame = () => {
		setBlendShapeQueue(prev => [...prev, { frameIndex: -1, blendShapes: [], timestamp: 0 }]);
	};

	const addToBlendShapeQueue = (frame: BlendShapeFrame) => {
		setBlendShapeQueue(prev => [...prev, frame]);
	};

	const clearBlendShapeQueue = () => {
		setBlendShapeQueue([]);
	};

	const popBlendShapeQueue = () => {
		return blendShapeQueue.shift();
	}

	return (
		<BlendShapeContext.Provider
			value={{

				addEndBlendShapeFrame,
				blendShapeQueue,
				addToBlendShapeQueue,
				clearBlendShapeQueue,
				popBlendShapeQueue
			}}
		>
			{children}
		</BlendShapeContext.Provider>
	);
};

// Custom hook to use the blend shapes context
export const useBlendShapes = (): BlendShapeContextType => {
	const context = useContext(BlendShapeContext);
	if (context === undefined) {
		throw new Error('useBlendShapes must be used within a BlendShapeProvider');
	}
	return context;
};