import React, { useEffect, useState } from 'react';


// vocabulary

import WordPictureMatch from './whiteboard/vocabulary/WordPictureMatch';
import FillInTheBlank from './whiteboard/grammar/FillInTheBlank';
import ListenAndAnswer from './whiteboard/listening/ListenAndAnswer';
import RoleplayConversation from './whiteboard/speaking/RoleplayConversation';
import ReadAndAnswer from './whiteboard/reading/ReadAndAnswer';

import { useServer } from '../../hooks/useServer';
import { useAzure } from '../../hooks/useAzure';
import { Exercise } from '../../types';
import "./Whiteboard.css";


interface WhiteboardProps {
	width: number;
	height: number;
	lesson: string;
	state: string;
	startExercise: () => void;
	exercise: Exercise | null;
	results: any;
	setResults: (results: any) => void;
	help: (content: string) => void;
	handleCheck: () => void;
	handleSubmit: () => void;
}


const exerciseMap = {
	"1b": "Word Picture Match",
	"2a": "Fill in the Blank",
	"4b": "Listen and Answer",
	"5a": "Roleplay Conversation",
	"6a": "Read and Answer",
} as { [key: string]: string; };



const Whiteboard: React.FC<WhiteboardProps> = ({ width, height, exercise, state, startExercise, results, setResults, help, handleCheck, handleSubmit }) => {


	const renderLessonComponent = () => {
		switch (exercise?.exerciseType) {
			// vocabulary

			case '1b':
				return <WordPictureMatch items={exercise.content} results={results} setResults={setResults} />
			case '2a':
				return <FillInTheBlank content={exercise.content} results={results} setResults={setResults} />
			case '4b':
				return <ListenAndAnswer content={exercise.content} results={results} setResults={setResults} />
			case '5a':
				return <RoleplayConversation dialogue={exercise.content.dialogue} setResults={setResults} />
			case '6a':
				return <ReadAndAnswer results={results} setResults={setResults} content={exercise.content} />

			default:
				return (null);
		}
	};

	return (
		<div
			style={{ width, height }}
			className="whiteboard border-2 border-gray-200 rounded-2xl bg-white overflow-y-scroll relative
                      shadow-[0_0_20px_rgba(0,0,0,0.1)] 
                      transition-all duration-300 hover:shadow-[0_0_25px_rgba(0,0,0,0.15)]
                      backdrop-blur-sm bg-white/90"
		>
			{/* Fixed buttons container */}
			{state === "exercise" && (
				<div className="absolute top-4 right-4 flex gap-3 z-10">
					<button
						onClick={handleCheck}
						className="bg-blue-500 text-white px-6 py-2.5 rounded-full
                                 hover:bg-blue-600 transition-all duration-300 
                                 transform hover:scale-105 hover:shadow-lg
                                 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
						disabled={!results}
					>
						Check Answers
					</button>
					<button
						onClick={handleSubmit}
						className="bg-green-500 text-white px-6 py-2.5 rounded-full
                                 hover:bg-green-600 transition-all duration-300
                                 transform hover:scale-105 hover:shadow-lg
                                 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
						disabled={!results}
					>
						Submit
					</button>
				</div>
			)}

			{state === "start" ? (
				<div className="h-full flex items-center">
					<div className="max-w-md mx-auto p-10 bg-gradient-to-br from-white to-gray-50
                                  rounded-2xl shadow-xl text-center transform transition-all duration-300
                                  hover:shadow-2xl border border-gray-100">
						<h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent 
                                     bg-gradient-to-r from-blue-500 to-purple-500">
							Start Exercise
						</h2>
						<p className="text-gray-600 mb-8 leading-relaxed">
							<span className="font-medium">Lesson Type:</span>
							<span className="ml-2 text-purple-600">
								{exercise?.exerciseType ? exerciseMap[exercise.exerciseType] : 'Loading...'}
							</span>
							<br />
							<span className="font-medium">Total Questions:</span>
							<span className="ml-2 text-purple-600">
								{exercise?.content.length || 'N/A'}
							</span>
						</p>
						<button
							onClick={startExercise}
							className="bg-gradient-to-r from-blue-500 to-purple-500
                                     text-white py-4 px-10 rounded-full font-medium
                                     hover:from-blue-600 hover:to-purple-600
                                     transform hover:scale-105 transition duration-300
                                     shadow-md hover:shadow-xl
                                     focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-opacity-50"
						>
							Start Now
						</button>
					</div>
				</div>
			) : renderLessonComponent()}
		</div>
	);
};

export default Whiteboard;
