import React, { useState, useRef, useEffect } from "react";
import { useServer } from "../hooks/useServer";
import { useTranslation } from "react-i18next";
import { useAzure } from "../hooks/useAzure";
import { getISO_639_1, getLanguage } from "language-flag-colors";
import { useLoading } from "../hooks/LoadingProvider";
import { VscClose } from "react-icons/vsc";
import { BiVolumeFull, BiHelpCircle, BiGlobe } from "react-icons/bi";
import { Message } from "../types";

interface MenuPosition {
	x: number;
	y: number;
}

const CustomContextMenu: React.FC = () => {
	const [selection, setSelection] = useState<string>("");
	const { speakTextAsync } = useAzure();
	const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
	const [menuPosition, setMenuPosition] = useState<MenuPosition>({ x: 0, y: 0 });
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const menuRef = useRef<HTMLDivElement>(null);
	const { i18n, t } = useTranslation();
	const [showModal, setShowModal] = useState<boolean>(false);
	const [text, setText] = useState<string>("");
	const { showLoading, hideLoading } = useLoading();
	const { translate, user, chat } = useServer();

	useEffect(() => {
		const handleDocumentClick = (event: MouseEvent) => {
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setShowMenu(false);
			}
		};

		const disableContextMenu = (e: MouseEvent) => {
			const selection = window.getSelection();
			if (!selection || !selection.toString()) {
				return;
			}

			setMenuPosition({ x: e.clientX, y: e.clientY });
			setShowMenu(true);
			setSelection(selection.toString());
			e.preventDefault();
		};

		document.addEventListener("click", handleDocumentClick);
		document.addEventListener("contextmenu", disableContextMenu);

		return () => {
			document.removeEventListener("click", handleDocumentClick);
			document.removeEventListener("contextmenu", disableContextMenu);
		};
	}, []);

	const handleSpeak = (e: React.MouseEvent) => {
		e.preventDefault();
		if (!selection || isSpeaking) return;

		setIsSpeaking(true);
		const text = selection.replace(/\n/g, " ");

		speakTextAsync({
			text,
			cb: () => {
				setIsSpeaking(false);
			},
		});

		setShowMenu(false);
	};

	const handleTranslate = async (e: React.MouseEvent) => {
		e.preventDefault();
		if (!selection) return;

		const languageCode = getISO_639_1(user.nativeLanguage);
		const translated = await translate(selection, "auto", languageCode || "en");
		setText(translated);
		setShowMenu(false);
		setShowModal(true);
	};

	const handleExplain = async (e: React.MouseEvent) => {
		e.preventDefault();
		if (!selection) return;

		showLoading("Explaining...");
		setShowMenu(false);

		const messages: Message[] = [{
			role: "system",
			content: `Please explain in very simple beginner English what the following text means: ${selection}`,
		}];

		const response = await chat(messages);
		hideLoading();
		setText(response);
		setShowModal(true);
	};

	return (
		<>
			{showMenu && (
				<div
					ref={menuRef}
					className="absolute bg-white border border-gray-200 rounded-lg shadow-lg z-50"
					style={{
						top: `${menuPosition.y}px`,
						left: `${menuPosition.x}px`,
					}}
				>
					<ul className="list-none m-0 p-0">
						<li
							onClick={handleSpeak}
							className="px-4 py-3 cursor-pointer flex items-center gap-2 border-b border-gray-100 hover:bg-gray-50 transition-colors"
						>
							<BiVolumeFull className="text-lg" />
							{isSpeaking ? t("stop") : t("speak")}
						</li>
						<li
							onClick={handleExplain}
							className="px-4 py-3 cursor-pointer flex items-center gap-2 border-b border-gray-100 hover:bg-gray-50 transition-colors"
						>
							<BiHelpCircle className="text-lg" />
							Explain
						</li>
						<li
							onClick={handleTranslate}
							className="px-4 py-3 cursor-pointer flex items-center gap-2 hover:bg-gray-50 transition-colors"
						>
							<BiGlobe className="text-lg" />
							Translate
						</li>
					</ul>
				</div>
			)}

			{showModal && (
				<div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
					<div className="bg-white rounded-lg w-full max-w-md mx-4">
						<div className="flex items-center justify-between px-4 py-3 border-b border-gray-200">
							<h2 className="text-lg font-semibold">
								{selection
									? selection.length > 30
										? selection.substring(0, 30) + "..."
										: selection
									: "Result"}
							</h2>
							<button
								onClick={() => setShowModal(false)}
								className="p-1 hover:bg-gray-100 rounded-full transition-colors"
							>
								<VscClose className="text-xl" />
							</button>
						</div>
						<div className="p-4 max-h-[70vh] overflow-y-auto">
							<p className="text-base leading-relaxed text-gray-700 whitespace-pre-wrap">
								{text}
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default CustomContextMenu;