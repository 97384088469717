import { useEffect, createContext, useContext, useState } from "react";
import { Message, RoleplayScores, Scenario } from "../types";
import { useAuth } from "./useAuth";
import { SERVER_URL, HTTP_SERVER_URL } from "../Constants";


interface Server {
	user: any | null;
	setUser: (user: any) => void;
	captionPhoto: (blob: Blob, instructions?: string) => Promise<string>;
	translate: (text: string, from: string, to: string) => Promise<string>;
	downloadBuild: (type: string) => Promise<string>;
	generateRoleplayScenario: (
		inputMode: "Speaking" | "Writing",
		outputMode: "Listening" | "Reading",
		draftScenario: undefined | string
	) => Promise<Scenario>;
	generateNewTask: (
		scenario: Scenario
	) => Promise<Scenario>;
	chat: (messages: any[]) => Promise<string>;
	getFeedbackAndScores: (
		scenario: Scenario
	) => Promise<[string[], RoleplayScores, any]>;
	getUserProfile: () => Promise<any>;
	registerUser: (userId: string) => Promise<boolean>; // Creates a user profile on dynamoDB after user is registered
	updateUser: (
		nativeLanguage: string,
		interests: string[],
	) => Promise<any>;
	getAudioURL: (objectKey: string) => Promise<string>;
	getContent: () => Promise<any>;
	getContentBySessionId: (sessionId: string) => Promise<any>;
	getPronunciationContent: () => Promise<any>;
	getPronunciationAssessmentContent: () => Promise<any>;
	postPronunciationAssessmentResults: (results: any,
		content: string[],
		sessionId: string,
		audioFiles: (Blob | null)[],
		isFrequency?: boolean,
		isAssessment?: boolean
	) => Promise<any>;
	transcribe: (audioBlob: Blob) => Promise<any>;
	generateImage: (text: string) => Promise<string>;
	getExercise: (messages: any[]) => Promise<any>;
	getExerciseFeedback: (exercise: any, exerciseResuls: any, messages: any[]) => Promise<any>;
	postExercise: (exercise: any, exerciseResuls: any, messages: any[]) => Promise<any>;
}

interface ServerProviderProps {
	children: React.ReactNode;
}

const ServerContext = createContext<Server | null>(null);

export const useServer = () => {
	const context = useContext(ServerContext);
	if (!context) {
		throw new Error("useServer must be used within a ServerProvider");
	}
	return context;
};

export const ServerProvider: React.FC<ServerProviderProps> = ({ children }) => {
	const { getAccessToken, currentUser } = useAuth();

	const [user, setUser] = useState<any>(null);
	let exerciseIndex = 0;
	const exerciseOrder = ["1b", "5a", "4b", "2a", "6a"];


	useEffect(() => {
		const populateProfile = async () => {
			for (let i = 0; i < 3 && !user; i++) {
				const userProfile = await getUserProfile();

				if (userProfile) {
					setUser(userProfile);
					break;
				}

				// sleep for 5 seconds to wait for db
				await new Promise((resolve) => setTimeout(resolve, 5000));
			}
		};

		if (!currentUser) {
			setUser(null);
		} else {
			populateProfile();
		}
	}, [currentUser]);

	const fetchWrapper = async (
		url: string,
		options: any = { headers: {} }
	) => {
		const token = await getAccessToken();
		if (!token) {
			throw new Error("No access token");
		}

		return fetch(url, {
			...options,
			headers: {
				...options.headers,
				Authorization: `Bearer ${token}`,
			},
		});
	};

	async function downloadBuild(
		type: string
	): Promise<any> {
		const url = `${SERVER_URL}/download-build/${type}`;

		const response = await fetchWrapper(url, {
			method: "GET",
		});

		if (!response.ok) {
			throw new Error("Unable to download build");
		}

		const responseJson = await response.json();

		return responseJson.signedUrl;
	}

	async function captionPhoto(
		blob: Blob,
		instructions: string = "Write ALL the text you see in the image."
	): Promise<string> {
		const formData = new FormData();
		formData.append("image", blob, "photo.jpeg");
		formData.append("instructions", instructions);

		const response = await fetchWrapper(`${SERVER_URL}/caption`, {
			method: "POST",
			body: formData,
		});

		if (!response.ok) {
			console.error(response);
			throw new Error("Unable to caption photo");
		}

		const responseJson = await response.json();

		const caption = responseJson.data[0] as string;

		return caption;
	}

	async function translate(text: string, from: string, to: string) {
		if (from === to) {
			return text;
		}

		const url = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=${from}&tl=${to}&dt=t&q=${encodeURIComponent(
			text
		)}`;

		for (let i = 0; i < 3; i++) {
			try {
				const response = await fetch(url);

				if (!response.ok) {
					console.error(response);
					throw new Error("Unable to translate");
				}

				const responseJson = await response.json();

				// concatenate all the translations
				const result = responseJson[0]
					.map((translation: string[]) => translation[0])
					.join("");

				//const result = responseJson[0][0][0];
				return result;
			} catch (error) {
				console.log(error, text);
				if (i == 3) {
					return null;
				}
			}
		}
	}

	async function generateRoleplayScenario(
		inputMode: "Speaking" | "Writing",
		outputMode: "Listening" | "Reading",
		draftScenario: undefined | string
	): Promise<Scenario> {
		const url = `${SERVER_URL}/generate-roleplay`;

		const response = await fetchWrapper(url, {
			method: "POST",
			body: JSON.stringify({ inputMode, outputMode, draftScenario }),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw new Error("Unable to generate roleplay scenario");
		}

		const generatedScenario = await response.json();

		console.log("Generated scenario", generatedScenario);

		const additional_context = {
			...Object.fromEntries(
				Object.entries(generatedScenario.scenario)
					.filter(([key]) => !isNaN(Number(key)))
					.map(([key, value]) => [key, value])
			),
		};

		const system_msg = `You are in a roleplay scenario with a user. 

## The User is given the following information:
Scenario Name: ${generatedScenario.scenario.Name}
Task: ${generatedScenario.scenario.Task}
Scenario: ${generatedScenario.scenario.Scenario}
Additional Context: ${additional_context ? JSON.stringify(additional_context) : "None"}


## Your instructions
${generatedScenario.scenario["Language Model Roleplay Instructions"]}

The user's CLB (Canadian Language Benchmark) ) level is ${generatedScenario.inputScore} for ${inputMode} and ${generatedScenario.outputScore} for ${outputMode}. You should adjust your language accordingly.

The user will be ${outputMode === "Listening" ? "listening to" : "reading"} your responses.

The user will be ${inputMode === "Speaking" ? "speaking" : "writing"} their responses.

Please use beginner level language with simple vocabulary and sentence structure and guide the conversation as needed.

You can end the conversation by saying "--End conversation--"`;

		// Convert the generated scenario to the Scenario interface
		const convertedScenario: Scenario = {
			id: generatedScenario.id,
			imgSrc: "",
			messages: [
				{
					role: "system",
					content: system_msg,
					scenarioName: generatedScenario.scenario.Name,
					task: generatedScenario.scenario.Task,
					roleplay_instructions:
						generatedScenario.scenario[
						"Language Model Roleplay Instructions"
						],
					additional_context: additional_context,
					description: generatedScenario.scenario.Scenario,
				},

				{
					role: "assistant",
					content: generatedScenario.scenario["First Message"],
				} as Message,
			],
			sampleVoiceResponse: "",
			voiceId: generatedScenario.voiceId,
			interest: generatedScenario.interest,
			role: generatedScenario.role,
			inputMode,
			outputMode,
			inputScore: generatedScenario.inputScore,
			outputScore: generatedScenario.outputScore,
		};

		console.log("Converted scenario", convertedScenario);

		return convertedScenario;
	}


	async function generateNewTask(scenario: Scenario): Promise<Scenario> {
		const url = `${SERVER_URL}/continue-roleplay`;

		const response = await fetchWrapper(url, {
			method: "POST",
			body: JSON.stringify({ scenario }),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw new Error("Unable to generate new task");
		}

		const generatedScenario = await response.json();


		const additional_context = {
			...Object.fromEntries(
				Object.entries(generatedScenario)
					.filter(([key]) => !isNaN(Number(key)) || key === "Additional Context")
					.map(([key, value]) => [key, value])
			),
		};

		const system_msg = `Continuing the roleplay scenario with the user.

## The User is given the new following information:
Task Name: ${generatedScenario.Name}
Task Description: ${generatedScenario.Task}
Additional Context: ${additional_context ? JSON.stringify(additional_context) : "None"}

## Your instructions
${generatedScenario["Language Model Roleplay Instructions"]}

The user's CLB (Canadian Language Benchmark) ) level is ${scenario.inputScore} for ${scenario.inputMode} and ${scenario.outputScore} for ${scenario.outputMode}. You should adjust your language accordingly.

The user will be ${scenario.outputMode === "Listening" ? "listening to" : "reading"} your responses.

The user will be ${scenario.inputMode === "Speaking" ? "speaking" : "writing"} their responses.

Remember that you can end the conversation by saying "--End conversation--"`;

		scenario.messages.push({
			role: "system",
			content: system_msg,
			scenarioName: generatedScenario.Name,
			task: generatedScenario.Task,
			description: generatedScenario.Scenario,
			roleplay_instructions:
				generatedScenario[
				"Language Model Roleplay Instructions"
				],
			additional_context: additional_context,
		});

		scenario.messages.push({
			role: "assistant",
			content: generatedScenario["First Message"],
		} as Message);

		return scenario;
	};


	async function chat(messages: Message[]): Promise<string> {
		const url = `${SERVER_URL}/chat`;

		const response = await fetchWrapper(url, {
			method: "POST",
			body: JSON.stringify({ messages }),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			console.error(response);
			throw new Error("Unable to chat");
		}

		const responseJson = await response.json();

		// remove any text in parenthesis and remove and non-ascci characters
		const text = responseJson["text"]
			.replace(/\(.*?\)/g, "")
			.replace(/[^\x00-\x7F]/g, "");

		return text;
	}

	async function getFeedbackAndScores(
		scenario: Scenario
	): Promise<[string[], RoleplayScores, any]> {
		const url = `${SERVER_URL}/feedback`;

		const response = await fetchWrapper(url, {
			method: "POST",
			body: JSON.stringify({ scenario }),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			console.error(response);
			throw new Error("Unable to chat");
		}

		const responseJson = await response.json();

		return [responseJson["feedback"], responseJson["scores"], responseJson["clb_scores"]];
	}

	// Get user profile
	async function getUserProfile(): Promise<any> {
		if (!currentUser || user) {
			return null;
		}

		const url = `${SERVER_URL}/users`;

		const response = await fetchWrapper(url, {
			method: "GET",
		});

		if (!response.ok) {
			throw new Error("Unable to retrieve user profile");
		}

		const userProfile = await response.json();

		setUser(userProfile);

		return userProfile;
	}

	// Update user profile
	async function updateUser(
		nativeLanguage: string,
		interests: string[],
	): Promise<any> {
		const url = `${SERVER_URL}/users`;

		const response = await fetchWrapper(url, {
			method: "PUT",
			body: JSON.stringify({
				nativeLanguage,
				interests,
			}),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw new Error("Unable to update user profile");
		}

		const user = await response.json();
		setUser(user.user);

		return user;
	}

	async function getAudioURL(objectKey: string): Promise<string> {
		// Encode the objectKey to ensure special characters are properly handled
		const encodedObjectKey = encodeURIComponent(objectKey);

		// Construct the URL with the objectKey as a query parameter
		const url = `${SERVER_URL}/audio?key=${encodedObjectKey}`;

		const response = await fetchWrapper(url, {
			method: "GET",
		});

		if (!response.ok) {
			throw new Error("Unable to retrieve audio URL");
		}

		const data = await response.json();
		return data.signedUrl; // Assuming the server returns an object with a signedUrl property
	}

	// Get content by sessionId
	async function getContentBySessionId(sessionId: string): Promise<any> {
		const url = `${SERVER_URL}/content/${sessionId}`;

		const response = await fetchWrapper(url, {
			method: "GET",
		});

		if (!response.ok) {
			throw new Error("Unable to retrieve content");
		}

		return response.json();
	}

	// Get content by user ID
	async function getContent(): Promise<any> {
		const url = `${SERVER_URL}/content`;

		const response = await fetchWrapper(url, {
			method: "GET",
		});

		if (!response.ok) {
			throw new Error("Unable to retrieve content");
		}

		return response.json();
	}

	async function getPronunciationContent(): Promise<any> {
		const url = `${HTTP_SERVER_URL}/pronunciation-content`;

		const response = await fetchWrapper(url, {
			method: "GET",
		});

		if (!response.ok) {
			throw new Error("Unable to retrieve pronunciation content");
		}

		return response.json();
	}

	async function getPronunciationAssessmentContent(): Promise<any> {
		const url = `${SERVER_URL}/pronunciation-assessment`;

		const response = await fetchWrapper(url, {
			method: "GET",
		});

		if (!response.ok) {
			throw new Error(
				"Unable to retrieve pronunciation assessment content"
			);
		}

		return response.json();
	}

	async function postPronunciationAssessmentResults(
		results: any,
		content: string[],
		sessionId: string,
		audioFiles: (Blob | null)[],
		isFrequency: boolean = false,
		isAssessment: boolean = false
	): Promise<any> {
		const url = `${SERVER_URL}/pronunciation-assessment`;

		const formData = new FormData();
		formData.append(
			"data",
			JSON.stringify({
				results,
				content,
				sessionId,
				isFrequency,
				isAssessment,

			})
		);

		audioFiles.forEach((blob, index) => {
			if (!blob) {
				return;
			}

			formData.append("audio", blob, `audio_${index}.wav`);
		});

		const response = await fetchWrapper(url, {
			method: "POST",
			body: formData,
		});

		if (!response.ok) {
			console.error(response);
			throw new Error("Unable to send results");
		}

		return response.json();
	}

	const transcribe = async (audioBlob: Blob) => {
		const formData = new FormData();
		formData.append("audio", audioBlob, "recording.wav");
		const response = await fetchWrapper(`${SERVER_URL}/transcribe`, {
			method: "POST",
			body: formData,
		});
		const responseJson = await response.json();
		return responseJson.text;
	};

	const generateImage = async (text: string) => {
		//encode the text to ensure special characters are properly handled
		const encodedText = encodeURIComponent(text);

		//construct the URL with the text as a query parameter
		const url = `${SERVER_URL}/image/${encodedText}`;

		const response = await fetchWrapper(url, {
			method: "GET",
		});

		if (!response.ok) {
			throw new Error("Unable to retrieve image");
		}

		const data = await response.json();
		return data.signedUrl; // Assuming the server returns an object with a signedUrl property
	}

	const getExercise = async (messages: any[]) => {

		const nextExerciseType = exerciseIndex < exerciseOrder.length ? exerciseOrder[exerciseIndex++] : null;

		// generate simple json objects with a type
		const response = await fetchWrapper(`${HTTP_SERVER_URL}/exercise-content`, {
			method: "POST",
			body: JSON.stringify({ messages: messages.slice(-10), nextExerciseType }),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			console.error(response);
			throw new Error("Unable to get exercise");
		}

		const responseJson = await response.json();
		return responseJson;
	}

	const getExerciseFeedback = async (exercise: any, exerciseResuls: any, messages: any[]) => {
		const response = await fetchWrapper(`${SERVER_URL}/exercise-feedback`, {
			method: "POST",
			body: JSON.stringify({ exercise, exerciseResuls, messages: messages.slice(-10) }),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			console.error(response);
			throw new Error("Unable to post exercise");
		}

		const responseJson = await response.json();
		return responseJson;
	}


	const postExercise = async (exercise: any, exerciseResuls: any, messages: any[]) => {
		const nextExerciseType = exerciseIndex < exerciseOrder.length ? exerciseOrder[exerciseIndex++] : null;

		const response = await fetchWrapper(`${HTTP_SERVER_URL}/exercise-result`, {
			method: "POST",
			body: JSON.stringify({ exercise, exerciseResuls, messages: messages.slice(-10), nextExerciseType }),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			console.error(response);
			throw new Error("Unable to post exercise");
		}

		const responseJson = await response.json();
		return responseJson;
	}

	const value: Server = {
		user,
		setUser,
		captionPhoto,
		translate,
		downloadBuild,
		generateNewTask,
		generateRoleplayScenario,
		chat,
		getFeedbackAndScores,
		getUserProfile,
		registerUser,
		updateUser,
		getAudioURL,
		getContent,
		getContentBySessionId,
		getPronunciationContent,
		getPronunciationAssessmentContent,
		postPronunciationAssessmentResults,

		transcribe,
		generateImage,
		getExercise,
		getExerciseFeedback,
		postExercise,
	};

	return (
		<ServerContext.Provider value={value}>
			{children}
		</ServerContext.Provider>
	);
};

// special case for registering user because it is called from useAuth
export async function registerUser(userId: string): Promise<any> {
	const url = `${SERVER_URL}/users`;

	const response = await fetch(url, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${userId}`,
		},
	});

	if (!response.ok) {
		console.error(response);
		throw new Error("Unable to register user");
	}

	return response.json();
}
