
import { useNavigate } from "react-router-dom";
import { IoAdd, IoPlayCircle, IoPauseCircle } from "react-icons/io5";



import "./Roleplay.css";

import React, { useState, useRef, useEffect } from "react";

import scenarios from "../../data/Scenarios";

import ScenarioCard from "../../components/ScenarioCard";
import { useServer } from "../../hooks/useServer";
import { useLocalStorage, setLocalStorage } from "../../hooks/useLocalStorage";
import { RoleplaySettingsProps, defaultRoleplaySettings, Scenario } from "../../types";
import { useLoading } from "../../hooks/LoadingProvider";
//import ScenarioCard from "../../components/roleplay/ScenarioCard";

const Roleplay: React.FC = () => {
	const { generateRoleplayScenario, user } = useServer();

	const navigate = useNavigate();
	const [searchText, setSearchText] = useState("");
	const [settings, setSettings] = useLocalStorage<RoleplaySettingsProps>(
		"RoleplaySettings",
		defaultRoleplaySettings
	);

	const { showLoading, hideLoading } = useLoading();

	const [currentRoleplayScenario, setCurrentRoleplayScenario] =
		useLocalStorage<Scenario | null>("currentRoleplayScenario", null);

	const [isPlaying, setIsPlaying] = useState(-1); // -1 is not playing, 0+ is playing that index
	const audioRef = useRef<HTMLAudioElement>(null);



	const [recommendedScenario, setRecommendedScenario] =
		useLocalStorage<Scenario | null>("recommendedScenario", null);

	const handleScenarioClick = (scenario: Scenario) => {
		console.log("Clicked on scenario", scenario);
		if (!scenario) {
			return;
		}

		setLocalStorage("currentRoleplayScenario", scenario);
		console.log("Stored scenario:", localStorage.getItem("currentRoleplayScenario"));

		// wait 1 second before navigating

		navigate("/chat");


	};

	const continueCurrentScenario = () => {
		if (currentRoleplayScenario) {
			navigate("/chat");
		}
	};

	const navigateToCreateRoleplay = () => {
		navigate("/create-your-own-roleplay");
	};

	const generateRandomScenario = async () => {
		const inputMode =
			settings.inputType === "text" ? "Writing" : "Speaking";
		const outputMode =
			settings.outputType === "text" ? "Reading" : "Listening";

		showLoading("Generating scenario...");
		const generatedScenario = await generateRoleplayScenario(
			inputMode,
			outputMode,
			undefined
		);

		console.log("Generated scenario", generatedScenario);

		setRecommendedScenario(generatedScenario);

		hideLoading();
	};

	const toggleAudio = (e: React.MouseEvent, scenario: Scenario) => {
		e.stopPropagation(); // Prevent the card's onClick from firing

		if (audioRef.current) {
			const audioPath = scenario.sampleVoiceResponse;

			if (isPlaying === -1) {
				// play
				audioRef.current.src = audioPath;
				audioRef.current.play();
				setIsPlaying(scenario.id);
				return;
			}

			if (isPlaying === scenario.id) {
				// pause
				audioRef.current.pause();
				setIsPlaying(-1);
				return;
			}

			// interrupt and play new audio
			audioRef.current.pause();
			audioRef.current.currentTime = 0;

			audioRef.current.src = audioPath;
			audioRef.current.play();
			setIsPlaying(scenario.id);
		}
	};

	useEffect(() => {
		if (recommendedScenario) return;
		generateRandomScenario();
	}, []);

	return (
		<div className="min-h-screen bg-gray-100">
			<div className="max-w-7xl mx-auto p-4">
				{/* Header Stats Mobile */}
				<div className="md:hidden bg-white rounded-lg shadow p-4 mb-6">
					<div className="grid grid-cols-4 gap-4">
						{['Reading', 'Writing', 'Speaking', 'Listening'].map((skill) => (
							<div key={skill} className="text-center">
								<div className="text-sm text-gray-600">{skill[0]}</div>
								<div className="font-semibold text-indigo-700">
									{user?.scores?.[skill]?.avg || "N/A"}
								</div>
							</div>
						))}
					</div>
				</div>

				<div className="grid md:grid-cols-12 gap-6">
					<div className="md:col-span-8">
						{/* Search and Create */}
						<div className="bg-white shadow-sm px-4 py-4 mb-6">
							<div className="flex items-center gap-2">
								<input
									type="search"
									className="flex-grow p-2 bg-gray-100 rounded-lg border-none focus:ring-2 focus:ring-indigo-500"
									value={searchText}
									onChange={(e) => setSearchText(e.target.value)}
									placeholder="Search scenarios"
								/>
								<button
									onClick={navigateToCreateRoleplay}
									className="flex items-center px-6 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg"
								>
									<IoAdd className="mr-2" />
									Create New
								</button>
							</div>
						</div>

						<div className="px-4">
							{/* Continue Scenario */}
							{currentRoleplayScenario && (
								<section className="mb-8">
									<h2 className="text-2xl font-bold text-gray-900 mb-4">
										Continue Where You Left Off
									</h2>
									<ScenarioCard
										scenario={currentRoleplayScenario}
										onClick={continueCurrentScenario}
										toggleAudio={(e) => toggleAudio(e, currentRoleplayScenario)}
										isPlaying={isPlaying === scenarios[0].id}
									/>
								</section>
							)}

							{/* Recommended Section */}
							<section className="mb-8">
								<h2 className="text-2xl font-bold text-gray-900 mb-4">
									Recommended for You
								</h2>
								{recommendedScenario && (
									<ScenarioCard
										scenario={recommendedScenario}
										onClick={() => handleScenarioClick(recommendedScenario)}
										toggleAudio={(e) => toggleAudio(e, recommendedScenario)}
										isPlaying={isPlaying === recommendedScenario.id}
										generateRoleplayScenario={generateRandomScenario}
									/>
								)}
							</section>

							{/* More Scenarios */}
							<section>
								<h2 className="text-2xl font-bold text-gray-900 mb-4">
									More Scenarios
								</h2>
								<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
									{scenarios.map((scenario) => (
										<ScenarioCard
											key={scenario.id}
											scenario={scenario}
											onClick={() => handleScenarioClick(scenario)}
											toggleAudio={(e) => toggleAudio(e, scenario)}
											isPlaying={isPlaying === scenario.id}
										/>
									))}
								</div>
							</section>
						</div>
					</div>

					{/* Sidebar */}
					<div className="md:col-span-4 px-4 space-y-4 border-l-2 bg-gray-200">
						<div className="bg-white rounded-lg shadow p-4">
							<h3 className="text-lg font-bold text-gray-900 mb-4">
								Your CLB Scores
							</h3>
							<div className="space-y-2">
								{['Reading', 'Writing', 'Speaking', 'Listening'].map((skill) => (
									<div key={skill} className="flex justify-between items-center">
										<span className="text-gray-600">{skill}</span>
										<span className="font-semibold text-indigo-700">
											{user?.scores?.[skill]?.avg || "N/A"}
										</span>
									</div>
								))}
							</div>
						</div>

						<div className="bg-white rounded-lg shadow p-4">
							<h3 className="text-lg font-bold text-gray-900 mb-4">
								Recommended Lessons
							</h3>
							{user?.recommendedLessons?.map((lesson: any, index: number) => (
								<div
									key={index}
									className="bg-indigo-100 text-indigo-700 rounded-full px-4 py-2 mb-2"
								>
									{lesson}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<audio ref={audioRef} />
		</div>
	);
};

export default Roleplay;

