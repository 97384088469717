import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	IoTimeOutline,
	IoCheckmarkCircleOutline,
	IoPlayCircleOutline,
	IoLogInOutline,
	IoPlayCircle
} from "react-icons/io5";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import {
	loadFromStorage,
	saveToStorage,
} from "../../hooks/useLocalStorage";
import { useServer } from "../../hooks/useServer";
import { PronunciationProgress } from "../../types";
import { generateUUID } from "../../utils/Helpers";
import { useAuth } from "../../hooks/useAuth";
import { useLoading } from "../../hooks/LoadingProvider";
import { useToast } from "../../hooks/ToastProvider";

Chart.register(CategoryScale);




const PronunciationPage: React.FC = () => {

	const navigate = useNavigate();
	const { currentUser } = useAuth();
	const { getPronunciationContent, getPronunciationAssessmentContent, user } =
		useServer();
	const { showLoading, hideLoading } = useLoading();

	const { showToast, hideToast } = useToast();

	const [pronunciationProgress, setPronunciationProgress] =
		useState<PronunciationProgress | null>(null);

	const practiceMaterial = user?.pronunciationModules || null;


	//useLocalStorage(`${currentUser?.uid}-practiceMaterial`, null);

	/*useEffect(() => {
		const resume = async () => {
			showLoading("Loading practice content...");
			const progress = await loadFromStorage<PronunciationProgress | null>("pronunciationProgress", null);
			setPronunciationProgress(progress);

			const practiceMaterial = await getPronunciationContent();
			if (practiceMaterial.modules) {
				console.log("Practice material:", practiceMaterial);
				setPracticeMaterial(practiceMaterial.modules);
			}
			hideLoading();
		};
		resume();
	}, []);*/

	const handleResume = () => {
		console.log("Resuming practice");
		navigate("/pronunciationPractice");
	};

	const handleCancelResume = async () => {
		await saveToStorage("pronunciationProgress", null);
		setPronunciationProgress(null);
	};

	const handleStartPractice = async (list: any, name: string, isFrequency: boolean = false) => {
		console.log("Starting practice with:", list);

		const progress = {
			sessionId: generateUUID(),
			speechElements: list,
			isAssessment: false,
			name,
			isFrequency,
		};

		await saveToStorage("pronunciationProgress", progress);

		navigate("/pronunciationPractice");
	};



	const handleStartAssessment = async () => {
		showLoading("Loading assessment content...");
		try {
			const { letters, words, sentences } =
				await getPronunciationAssessmentContent();

			const speechElements = [...letters, ...words, ...sentences];

			const progress = {
				sessionId: generateUUID(),
				speechElements: speechElements.map((el) => ({ word: el })),
				isAssessment: true,
				isFrequency: false,
			};

			console.log("Assessment progress:", progress);

			await saveToStorage("pronunciationProgress", progress);
			hideLoading();
			navigate("/pronunciationPractice");
		} catch (error) {
			console.error(error);
			showToast("Failed to load assessment content");
			hideLoading();
			return;
		}
	};

	const navigateToHistory = () => {
		navigate("/pronunciationHistory");
	};

	useEffect(() => {
		if (!user) {
			showLoading("Loading...");
		} else {
			hideLoading();
		}
	}, [user]);


	const renderCategoryCards = (category: string, wordLists: any) => {
		const scrollableClass = "flex overflow-x-auto whitespace-nowrap space-x-4 pb-4 no-scrollbar scroll-smooth overflow-y-hidden";
		const toRomanNumeral = (num: number) => {
			const romanNumerals = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
			return romanNumerals[num - 1] || num.toString();
		};

		if (category === 'frequency') {
			const wordSetNumber = Math.floor((user.frequency_index || 0) / 20);
			return (
				<div className="mb-8 mt-8">
					<h2 className="sm:text-xl text-lg font-bold mb-4 text-gray-800">
						Continue Your English Mastery 🚀
					</h2>

					<div className={scrollableClass}>
						<div
							className="bg-gradient-to-r from-blue-500 to-purple-600 rounded-xl p-0.5 hover:scale-105 transition-transform duration-300 cursor-pointer shadow-lg"
							onClick={() => handleStartPractice(wordLists, `Word Set ${wordSetNumber}`, true)}
						>
							<div className="bg-white dark:bg-gray-800 rounded-lg p-4">
								<div className="flex flex-col items-center">
									<div className="text-3xl mb-2">📚</div>
									<h3 className="font-semibold text-base mb-1">Word Set
										{" " + wordSetNumber}
									</h3>
									<div className="flex items-center">

										<span className="text-sm text-gray-500 ml-2">20 words</span>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		if (category === 'interests') {

			return (
				<div className="w-full">
					{Object.entries(wordLists).map(([interest, modules]: [string, any]) => (
						<div key={interest} className="mb-8">
							<h2 className="sm:text-xl text-lg font-bold mb-4 text-gray-800">
								Because you're interested in
								<span className="text-primary pl-1 py-1 rounded-md">
									{interest}
								</span>
								...
							</h2>

							<div className={`${scrollableClass} gap-4`}>
								{modules.map((module: any, idx: number) => (
									<div
										key={idx}
										className="w-48 flex-shrink-0 transform transition-all duration-300 hover:scale-105 cursor-pointer"
										onClick={() => handleStartPractice(module.words, interest)}
									>
										<div className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-shadow">
											<div className="relative h-48">
												{module.image ? (
													<img
														src={module.image.url}
														alt={module.image.alt}
														className="w-full h-full object-cover"
													/>
												) : (
													<div className="w-full h-full bg-gradient-to-br from-primary/20 to-primary/40 flex items-center justify-center">
														<span className="text-4xl">📚</span>
													</div>
												)}

												{/* Overlay gradient for better text readability */}
												<div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />

												<div className="absolute bottom-0 w-full p-3">
													<h3 className="text-white font-bold text-lg text-center">
														{interest} {toRomanNumeral(idx + 1)}
													</h3>
													<p className="text-white/80 text-sm text-center mt-1">
														{module.words.length} words
													</p>
												</div>
											</div>

											<div className="p-3 border-t border-gray-100">
												<div className="flex items-center justify-between text-sm">
													<span className="text-gray-600">
														Module {idx + 1}
													</span>
													<span className="text-primary font-medium">
														Start →
													</span>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			);
		}

		if (category === 'weaknesses') {
			return (
				<div className="mb-6">
					<h2 className="sm:text-xl text-lg font-bold mb-4 text-gray-800">
						Improve your pronunciation in these areas...
					</h2>
					<div className={`${scrollableClass} flex flex-wrap gap-4`}>
						{Object.entries(wordLists).map(([sound, words]: [string, any], idx) => (
							<div
								key={sound}
								className="w-48 flex-shrink-0 transform transition-all duration-300 hover:scale-105 cursor-pointer"
								onClick={() => handleStartPractice(words[0].words, sound)}
							>
								<div className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-shadow">
									<div className="relative h-48">
										<div className="w-full h-full bg-gradient-to-br from-blue-500/20 to-purple-600/40 flex items-center justify-center">
											<span className="text-4xl">🔊</span>
										</div>

										{/* Overlay gradient for text readability */}
										<div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />

										<div className="absolute bottom-0 w-full p-3">
											<h3 className="text-white font-bold text-lg text-center">
												{sound.toUpperCase()} Sound
											</h3>
											<p className="text-white/80 text-sm text-center mt-1">
												{words[0].words.length} words
											</p>
										</div>
									</div>

									<div className="p-3 border-t border-gray-100">
										<div className="flex items-center justify-between text-sm">
											<span className="text-gray-600">
												Module {idx + 1}
											</span>
											<span className="text-primary font-medium">
												Start →
											</span>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			);
		}



		return null;
	};

	return (
		<div className="min-h-screen bg-gray-50">
			<div className="container mx-auto px-4 py-6">
				{pronunciationProgress && (
					<div className="mt-12 max-w-2xl mx-auto cursor-pointer" onClick={handleResume}>
						{/* Resume section content remains the same */}
					</div>
				)}

				{practiceMaterial ? (
					<div className="grid gap-8">
						{renderCategoryCards("frequency", practiceMaterial["frequency"])}
						{renderCategoryCards("interests", practiceMaterial["interests"])}
						{renderCategoryCards("weaknesses", practiceMaterial["weaknesses"])}
					</div>
				) : (
					<div className="flex flex-col items-center justify-center min-h-[50vh] space-y-4">
						<button
							className="w-full max-w-md bg-blue-600 text-white rounded-full px-6 py-3 flex items-center justify-center gap-2 hover:bg-blue-700 transform hover:scale-105 transition-all duration-300"
							onClick={handleStartAssessment}
						>
							<IoCheckmarkCircleOutline className="text-2xl" />
							<span className="font-semibold text-lg">Start Assessment</span>
						</button>
						<p className="text-center text-gray-600 max-w-md px-4">
							This assessment will evaluate your pronunciation proficiency. It will take approximately 5 minutes to complete. Please ensure you're in a quiet space.
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default PronunciationPage;
