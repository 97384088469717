import { useLocation, Link } from "react-router-dom";
import { FaHammer, FaMicrophone, FaUser, FaComments, FaCog } from "react-icons/fa";
import { useState, useEffect } from "react";

const SideMenu = () => {
	const location = useLocation();

	if (!["/tools", "/pronunciation", "/profile", "/roleplay", "/settings"].includes(location.pathname)) {
		return null;
	}

	return (
		<div className="w-64 h-screen bg-white border-r-2 shadow-lg">
			<header className="bg-gray-200 p-4">
				<img
					src="wordmark-black.png"
					alt="Logo"
					className="w-40 h-30 mx-auto"
				/>
			</header>

			<nav className="p-2">
				<ul className="space-y-2">
					<li>
						<Link
							to="/tools"
							className={`flex items-center p-3 rounded-lg  transition-colors ${location.pathname === "/tools" ? "bg-blue-500 text-white" : "text-gray-700 hover:bg-blue-300"
								}`}
						>
							<FaHammer className="mr-3 text-xl" />
							<span>Tools</span>
						</Link>
					</li>

					<li>
						<Link
							to="/pronunciation"
							className={`flex items-center p-3 rounded-lg transition-colors ${location.pathname === "/pronunciation" ? "bg-blue-500 text-white" : "text-gray-700 hover:bg-blue-300"
								}`}
						>
							<FaMicrophone className="mr-3 text-xl" />
							<span>Pronunciation</span>
						</Link>
					</li>

					<li>
						<Link
							to="/profile"
							className={`flex items-center p-3 rounded-lg  transition-colors ${location.pathname === "/profile" ? "bg-blue-500 text-white" : "text-gray-700 hover:bg-blue-300"
								}`}
						>
							<FaUser className="mr-3 text-xl" />
							<span>Profile</span>
						</Link>
					</li>

					<li>
						<Link
							to="/roleplay"
							className={`flex items-center p-3 rounded-lg transition-colors ${location.pathname === "/roleplay" ? "bg-blue-500 text-white" : "text-gray-700 hover:bg-blue-300"
								}`}
						>
							<FaComments className="mr-3 text-xl" />
							<span>Roleplay</span>
						</Link>
					</li>

					<li>
						<Link
							to="/settings"
							className={`flex items-center p-3 rounded-lg  transition-colors ${location.pathname === "/settings" ? "bg-blue-500 text-white" : "text-gray-700 hover:bg-blue-300"
								}`}
						>
							<FaCog className="mr-3 text-xl" />
							<span>Settings</span>
						</Link>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default SideMenu;