import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoHelpCircleOutline } from "react-icons/io5";
import MessageItem from "./MessageItem";
import FeedbackItem from "./FeedbackItem";
import { Scenario, Message, RoleplaySettingsProps } from "../../types";
import { useAzure } from "../../hooks/useAzure";
import { isObjectEmpty } from "../../utils/Helpers";
import { useLoading } from "../../hooks/LoadingProvider";

interface MessageListProps {
	hasFeedback: boolean;
	scenario: Scenario;
	settings: RoleplaySettingsProps;
	unblurredSet: Set<number>;
	setUnblurredSet: React.Dispatch<React.SetStateAction<Set<number>>>;
	isPlayingIndex: number;
	setIsPlayingIndex: React.Dispatch<React.SetStateAction<number>>;
}

const MessageList: React.FC<MessageListProps> = ({
	hasFeedback,
	scenario,
	settings,
	unblurredSet,
	setUnblurredSet,
	isPlayingIndex,
	setIsPlayingIndex,
}) => {
	const { speakTextAsync, stopTextAsync, auth, ensureToken } = useAzure();
	const { showLoading, hideLoading } = useLoading();

	useEffect(() => {
		return () => {
			setIsPlayingIndex(-1);
			stopTextAsync();
		};
	}, []);

	useEffect(() => {
		if (!auth) {
			ensureToken();
			showLoading();
		} else {
			hideLoading();
		}
	}, [auth]);

	const messages = scenario.messages as Message[];

	useEffect(() => {
		const lastMessage = scenario.messages[scenario.messages.length - 1];

		if (lastMessage.role === "assistant" && !hasFeedback) {

			handleAudio(scenario.messages.length - 1);
		}
	}, [scenario?.messages]);

	const handleAudio = (index: number) => {
		if (!scenario) return;

		if (isPlayingIndex === index) {
			stopTextAsync();
			setIsPlayingIndex(-1);
		} else if (isPlayingIndex !== -1) {
			stopTextAsync();
			setIsPlayingIndex(index);
			speakTextAsync({
				text: messages[index].content,
				voice: scenario.voiceId,
				cb: () => setIsPlayingIndex(-1),
			});
		} else {
			setIsPlayingIndex(index);
			speakTextAsync({
				text: messages[index].content,
				voice: scenario.voiceId,
				cb: () => setIsPlayingIndex(-1),
			});
		}
	};

	// Cleanup effect when leaving the page
	useEffect(() => {
		return () => {
			setIsPlayingIndex(-1);
			stopTextAsync();
		};
	}, []);

	return (
		<div className="space-y-6 h-full">
			{messages.map((message, index) => (
				<React.Fragment key={index}>
					{message.role === "system" ? (
						<div className="mb-4 bg-white rounded-lg shadow p-4 relative">
							<p className="mb-2">
								<strong>Description:</strong> {message.description}
							</p>
							<p className="mb-2">
								<strong>Task:</strong> {message.task}
							</p>
							{!isObjectEmpty(message.additional_context) && (
								<p className="mb-2">
									<strong>Additional Context:</strong>{" "}
									{Object.values(message.additional_context).join(" ")}
								</p>
							)}
						</div>
					) : (
						<React.Fragment>
							<MessageItem
								hasFeedback={hasFeedback}
								message={message}
								index={index}
								settings={settings}
								unblurredSet={unblurredSet}
								setUnblurredSet={setUnblurredSet}
								handleAudio={handleAudio}
								isPlayingIndex={isPlayingIndex}
							/>
							<FeedbackItem
								message={message}
								feedback={message.feedback}
							/>
						</React.Fragment>
					)}
				</React.Fragment>
			))}
		</div>
	);
};

export default MessageList;