import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';

const PrivacyPolicy: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div className="min-h-screen flex flex-col">
			<header className="bg-white border-b">
				<div className="px-4 h-14 flex items-center">
					<button
						onClick={() => navigate(-1)}
						className="flex items-center text-blue-600 mr-4"
					>
						<IoArrowBack className="text-xl" />
						<span className="ml-1">Back</span>
					</button>
					<h1 className="text-lg font-semibold">Privacy Policy</h1>
				</div>
			</header>

			<main className="flex-1 p-4 overflow-y-auto">
				<div className="max-w-3xl mx-auto">
					<h1 className="text-2xl font-bold mb-4">Privacy Policy for Fluent Future</h1>

					<div>
						<p className="mb-4">
							Last updated: 2024-10-08
						</p>

						<h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
						<p className="mb-4">
							Welcome to Fluent Future, a language learning application designed for newcomers in Canada. This Privacy Policy explains how we collect, use, and protect your personal information.
						</p>

						<h2 className="text-xl font-semibold mb-2">2. Information We Collect</h2>
						<p className="mb-4">
							We collect the following types of information:
						</p>
						<ul className="list-disc pl-6 mb-4">
							<li>Pronunciation data: We record and store audio of your pronunciation exercises to help improve your language skills.</li>
							<li>Audio recordings: We collect audio recordings for data collection purposes to enhance our language learning algorithms and improve the app's effectiveness.</li>
						</ul>

						<h2 className="text-xl font-semibold mb-2">3. How We Use Your Information</h2>
						<p className="mb-4">
							We use the collected information for the following purposes:
						</p>
						<ul className="list-disc pl-6 mb-4">
							<li>To provide personalized language learning experiences</li>
							<li>To improve our speech recognition and pronunciation assessment algorithms</li>
							<li>To enhance the overall quality and effectiveness of our application</li>
						</ul>

						<h2 className="text-xl font-semibold mb-2">4. Data Protection</h2>
						<p className="mb-4">
							We take the security of your data seriously. All audio recordings and pronunciation data are encrypted and stored securely. We do not share your personal information with third parties without your explicit consent.
						</p>

						<h2 className="text-xl font-semibold mb-2">5. Your Rights</h2>
						<p className="mb-4">
							You have the right to:
						</p>
						<ul className="list-disc pl-6 mb-4">
							<li>Access the personal data we hold about you</li>
							<li>Request the deletion of your data</li>
							<li>Opt-out of data collection for research purposes</li>
						</ul>

						<h2 className="text-xl font-semibold mb-2">6. Changes to This Policy</h2>
						<p className="mb-4">
							We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.
						</p>

						<h2 className="text-xl font-semibold mb-2">7. Contact Us</h2>
						<p className="mb-4">
							If you have any questions about this Privacy Policy, please contact us at:
						</p>
						<p className="mb-4">
							Fluent Future<br />
							Email: info@fluentfuture.ca<br />
						</p>
					</div>
				</div>
			</main>
		</div>
	);
};

export default PrivacyPolicy;