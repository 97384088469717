import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { getPhotosAndCaptions, deletePhoto } from "../hooks/useCamera";
import { Image } from "../types";

const Gallery: React.FC = () => {
	const [images, setImages] = useState<Image[]>([]);
	const [selectedImage, setSelectedImage] = useState<Image | null>(null);
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const loadPhotos = async () => {
			const photos = await getPhotosAndCaptions();
			console.log(photos);
			const images = photos.map((photo: any) => ({
				src: photo.webviewPath,
				caption: photo.caption || "No caption",
				filepath: photo.filepath,
			}));
			setImages(images);
		};

		loadPhotos();
	}, []);

	const deleteImage = async (index: number) => {
		const image = images[index];
		console.log("Deleting image", image);
		await deletePhoto(image.filepath.split("/").pop() || "");
		const newImages = images.filter((_, i) => i !== index);
		setImages(newImages);
	};

	const handleImageClick = (image: Image) => {
		setSelectedImage(image);
		setShowModal(true);
	};

	const closeFullscreen = () => {
		setShowModal(false);
		setSelectedImage(null);
	};

	return (
		<div className="min-h-screen bg-white">
			<header className="bg-white border-b">
				<div className="px-4 h-14 flex items-center justify-between">
					<button
						onClick={() => navigate(-1)}
						className="flex items-center text-gray-600"
					>
						<IoArrowBack size={24} />
					</button>
					<h1 className="text-lg font-semibold">Gallery</h1>
					<div className="w-8"></div> {/* Spacer for alignment */}
				</div>
			</header>

			<main className="p-4 pb-24">
				<div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
					{images.map((image, index) => (
						<div
							key={index}
							className="relative overflow-hidden rounded-lg shadow-md transition-transform duration-300 hover:scale-105 cursor-pointer"
							onClick={() => handleImageClick(image)}
						>
							<img
								src={image.src}
								alt={image.caption}
								className="w-full h-48 sm:h-64 object-cover"
							/>
							<div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent">
								<p className="absolute bottom-0 left-0 right-0 p-4 text-white text-sm font-medium truncate">
									{image.caption}
								</p>
							</div>
							<button
								onClick={(e) => {
									e.stopPropagation();
									deleteImage(index);
								}}
								className="absolute top-2 right-2 p-2 rounded-full bg-red-500 hover:bg-red-600 text-white transition-colors"
							>
								<FaTrash size={16} />
							</button>
						</div>
					))}
				</div>
			</main>

			{showModal && (
				<div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
					<div className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
						<div className="p-4 text-center">
							<img
								src={selectedImage?.src}
								alt={selectedImage?.caption}
								className="w-full h-auto object-cover mb-4"
							/>
							<p className="text-center text-md text-gray-600">
								{selectedImage?.caption}
							</p>
							<button
								onClick={closeFullscreen}
								className="mt-4 px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
							>
								Close
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Gallery;