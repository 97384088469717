import React, { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useServer } from '../hooks/useServer';

const HotStreak: React.FC = () => {
	const { user } = useServer();
	const [show, setShow] = useState(false);
	const [activeDays, setActiveDays] = useState<Set<string>>(new Set());

	function getLastFiveDays() {
		const dates = [];
		const today = new Date();

		for (let i = 0; i < 5; i++) {
			const date = new Date(today);
			date.setDate(today.getDate() - i);
			dates.unshift(date.toISOString().split('T')[0]);
		}
		return dates;
	}

	function getWeekday(dateString: string) {
		const date = new Date(dateString);
		const day = date.toLocaleDateString('en-US', { weekday: 'short', timeZone: 'UTC' });
		return day.substring(0, 2).toUpperCase();
	}

	useEffect(() => {
		if (!user?.lastScoreUpdate) return;

		const today = new Date().toISOString().split('T')[0];
		const lastUpdate = new Date(user.lastScoreUpdate).toISOString().split('T')[0];

		if (today === lastUpdate) {
			setShow(true);
			setActiveDays(new Set(user.lastSevenDays || []));

			setTimeout(() => {
				setShow(false);
			}, 5000);
		}
	}, [user?.lastScoreUpdate]);

	if (!show) return null;

	const lastFiveDays = getLastFiveDays();

	return (
		<div className="fixed top-0 left-0 w-full flex justify-center z-50 pointer-events-none">
			<div className={`
                transform transition-transform duration-500 
                ${show ? 'translate-y-0' : '-translate-y-full'}
                bg-gradient-to-r from-primary to-secondary
                rounded-b-xl shadow-2xl p-6
                max-w-md w-11/12 pr-0 pl-0
            `}>
				<div className="flex items-center justify-center mb-4">
					<div className="bg-white/20 rounded-full px-4 py-1 flex items-center">
						<span className="text-white mr-2">🔥</span>
						<span className="text-white font-bold">{user?.streak || 0} Day Streak!</span>
					</div>
				</div>

				<div className="flex justify-center">
					{lastFiveDays.map((date: string) => (
						<div key={date} className="flex flex-col items-center mx-2">
							<div className={`
                                w-10 h-10 sm:w-12 sm:h-12
                                rounded-lg
                                flex items-center justify-center
                                transition-all duration-300
                                ${activeDays.has(date)
									? 'bg-white shadow-lg transform scale-105'
									: 'bg-white/20 border-2 border-white/30'}
                            `}>
								{activeDays.has(date) && (
									<FaCheckCircle className="text-primary text-xl" />
								)}
							</div>
							<span className={`
                                text-xs sm:text-sm mt-2
                                ${activeDays.has(date) ? 'text-white' : 'text-white/70'}
                                font-medium
                            `}>
								{getWeekday(date)}
							</span>
						</div>
					))}
				</div>

				<div className="mt-4 text-center">
					<span className="text-white/80 text-sm">
						{user?.streak || 0 > 1 ? 'Keep going! You\'re on a roll!' : 'Start your streak today!'}
					</span>
				</div>
			</div>
		</div>
	);
};

export default HotStreak;